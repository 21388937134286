import React from "react";
import { firestore, auth } from "../../../providers/firebase";
import { withRouter } from "../../../providers/withRouter";

import Editar from '../../../icons/editar.svg'
import ModalEditar from './editarDatosAAFF'
import { FaRegEdit } from "react-icons/fa";
import Comunidades from "./Comunidades";
import Proveedores from "./Proveedores";

class AAFF extends React.Component {
  constructor() {
    super()
    this.state = {
      proveedor: undefined,
      declResp: {},
      aaffs: undefined,
      modalEditarOpened: false,
      modalNotificacionOpened: false

    }
  }
  componentDidMount() {
    this.loadPageContent()
  }

  loadPageContent = () => {
    //console.log(auth.currentUser.uid);
    firestore.collection("aaff").doc(auth.currentUser.uid).get().then(snapshot => {
      let aaff = snapshot.data();
      this.setState({ aaff: aaff})

    }).catch(error => {
      //window.location.replace("/")
      console.log(error)
    })
  }

  render() {
    if (!this.state.aaff) return null //Loading data...
    return <>
      <ModalEditar aaff={this.state.aaff} opened={this.state.modalEditarOpened} handleClose={() => {
          this.setState({ modalEditarOpened: false })
          this.loadPageContent()
      }} />

      <div className="pageHeader"></div>
      <div className="pageContainer">
        <div className="pageCard" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="gridCard">
            <div className="card">
              <div className="card-header editarSubhead">
                <div>INFORMACIÓN GENERAL</div>
                <div className="editarBtn" onClick={() => this.setState({ modalEditarOpened: true })}><span style={{ marginRight: 10 }} >Editar</span> <FaRegEdit /></div>
              </div>
              <div className="infoContent">
                <p><b>Nombre:</b> {this.state.aaff.razonSocial}</p>
                <p><b>NIF:</b> {this.state.aaff.nif}</p>
                <p><b>Municipio:</b> {this.state.aaff.municipio}</p>
                <p><b>Provincia:</b> {this.state.aaff.provincia}</p>
                <p><b>Email:</b> {this.state.aaff.email}</p>
                <p><b>Persona de contacto:</b> {this.state.aaff.contacto}</p>
                <p><b>Teléfono:</b> {this.state.aaff.telefono}</p>
              </div>
            </div>
          </div>
          <div className="gridCard">
            <div className="m-3">
              <Comunidades />
            </div>
          </div>
        </div>
        <div className="pageCard">
          <div className="gridCard">
            <Proveedores />
          </div>
        </div>
      </div>
      <style jsx="true">{`
          // .pageGrid{
          //     grid-template-columns: 1fr;
          // }
      `}</style>
    </>
  }
}

export default withRouter(AAFF);
