import React from "react";
import { Link } from "react-router-dom";
import { firestore, auth } from "../../../providers/firebase";


class Comunidades extends React.Component {
    constructor() {
        super()
        this.state = {
            fincas: []
        }
    }
    componentDidMount() {
        firestore.collection("fincas").where("nifProveedores", "array-contains", auth.currentUser.uid).orderBy("razonSocial").get().then(snapshot => {
            let fincas = []
            snapshot.docs.forEach(doc => {
                if(doc.data().activo === true || doc.data().activo === "true" || doc.data().activo === undefined){
                    fincas.push(doc.data())
                }
            })
            this.setState({ fincas: fincas })

        })
    }

    render() {
        if (this.state.fincas === []) return <></> //Loading data...
        return (
            <>
                <div className="pageHeader"></div>
                <div className="card" style={{margin:30}}>
                    <div className="card-header"><span>Comunidades</span></div>
                    <div className="tableWrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>Comunidad</th>
                                    <th>nif</th>
                                    <th>Dirección</th>
                                    <th>C.P.</th>
                                    <th>Municipio</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.fincas.map((e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><Link to={"/comunidades/" + e.nif}>{e.razonSocial}</Link></td>
                                            <td><Link to={"/comunidades/" + e.nif}>{e.nif}</Link></td>
                                            <td><Link to={"/comunidades/" + e.nif}>{e.direccion}</Link></td>
                                            <td><Link to={"/comunidades/" + e.nif}>{e.codigoPostal}</Link></td>
                                            <td><Link to={"/comunidades/" + e.nif}>{e.municipio}</Link></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }
}

export default Comunidades;

