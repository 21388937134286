import { LinearProgress, withStyles, Box } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { firestore, auth } from "../../../providers/firebase";

import CircularProgress from '@material-ui/core/CircularProgress';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: props => ({
    borderRadius: 5,
    backgroundColor: props.value < 25 ? '#FF5252' : (props.value < 75 ? "#fcba03" : "#7bc977"),
  })
}))(LinearProgress);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={2}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        {`${Math.round(
          props.value * 100
        ) / 100}%`}
      </Box>
    </Box>
  );
}

class Comunidades extends React.Component {
  
  constructor() {
    super()
    this.state = {
      fincas: [],
      isLoading: true
    }
  }
  componentDidMount() {
    firestore.collection("fincas").where("nifAAFF", "==", auth.currentUser.uid).orderBy('razonSocial').get().then(snapshot => {
      let data = []
      snapshot.docs.forEach(e => data.push(e.data()))
      this.setState({ fincas: data, isLoading: false })
    })
  }

  render() {
    const { isMainRoute } = this.props;
    const style = {
      margin: isMainRoute ? '30px' : '0px'
    };
    const scroll = {
      overflowY: 'auto',
      maxHeight: isMainRoute ? 'auto' : '500px'
    };
    return (
      <>
        <div className="card" style={style}>
          <div className="card-header"><span>Comunidades</span></div>
          <div className="tableWrapper" style={scroll}>
            <table>
              <thead>
                <tr>
                  <th>Comunidad</th>
                  <th>Municipio</th>
                  {/* <th>Activa</th> */}
                  <th>Estado actual</th>
                </tr>
              </thead>
              <tbody>
                {this.state.isLoading ? <tr><td colSpan='4' style={{textAlign:'center'}}><CircularProgress style={{color:'#848474'}} /> </td></tr> : 
                this.state.fincas.length === 0 ? <tr><td colSpan='4' style={{textAlign:'center'}}>No hay comunidades</td></tr> :
                this.state.fincas.map((e, i) => {
                  const color = e.activo === false || e.activo === "false" ? "red" : "#303248"
                  return (
                    <>
                      {e.activo === false || e.activo === "false" ? null :
                        <tr key={i}>
                          <td><Link to={"/comunidades/" + e.nif}>{e.razonSocial}</Link></td>
                          <td><Link to={"/comunidades/" + e.nif}>{e.municipio}</Link></td>
                          {/* <td style={{ color: color }}>{e.activo === undefined ? "Activa" : e.activo === "true" || e.activo === true ? "Activa" : "Inactiva"}</td> */}
                          <td><Link to={"/comunidades/" + e.nif}><LinearProgressWithLabel value={e.estado} variant="determinate" /></Link></td>
                        </tr>
                      }
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}

export default Comunidades;

