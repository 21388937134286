import React from "react";
import { Link } from "react-router-dom";
import { firestore, storage, auth } from "../../../providers/firebase";

import { toast } from "react-toastify";
import Lupa from "../../../icons/lupa.svg"
import { LinearProgress, withStyles, Box } from "@material-ui/core";
import Warning from "../../../icons/warning.svg"
import CircularProgress from '@material-ui/core/CircularProgress';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: props => ({
    borderRadius: 5,
    backgroundColor: props.value < 25 ? '#FF5252' : (props.value < 75 ? "#fcba03" : "#7bc977"),
  })
}))(LinearProgress);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={2}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        {props.label}
      </Box>
    </Box>
  );
}

class AAFF extends React.Component {
  constructor() {
    super()
    this.state = {
      aaff: [],
      tecnicos: {},
      consultores: {},
      comerciales: {},
      docsPendientes: [],
      proveedorDatos: [],
      provsnif: [],
      isLoading: true
    }
  }
  componentDidMount() {
    this.loadPageContent()
    this.loadTecnicosConsultoresComerciales()
  }

  loadPageContent = () => {
    let proveedores = {}
    let promises = []
    let provnif = []
    promises.push(firestore.collection("proveedores").get().then(querysnapshot => {
      querysnapshot.docs.forEach(proveedor => {
        proveedores = { ...proveedores, [proveedor.data().nif]: proveedor.data() }
        provnif.push(proveedor.data().nif)
      })
    
    }))
    Promise.all(promises).then(() => {
      this.setState({proveedorDatos:proveedores})
      this.setState({provsnif:provnif})
    })
    firestore.collection("aaff").where('nifComercial', '==', auth.currentUser.uid).orderBy("razonSocial").get().then(snapshot => {
      let promises = []
      let aaffs = []
      snapshot.docs.forEach(aaff => {
        promises.push(firestore.collection("fincas").where("nifAAFF", "==", aaff.data().nif).get().then(fincas => {
          let aaffData = aaff.data()
          //aaffData.nFincas = fincas.size
          let proveedores = []
          let fincasVisitadas = 0
          let nfincas = 0
          fincas.docs.forEach(finca => {
            if(finca.data().activo === undefined || finca.data().activo === true || finca.data().activo === "true"){
              nfincas++
            }
            proveedores = proveedores.concat(finca.data().nifProveedores)
            if (finca.data().documentos?.hojaVisita?.validado && finca.data().activo !== "false") {
              fincasVisitadas++
            }
          })
          proveedores = [...new Set(proveedores)]
          let nprof = 0
          for(let i=0; i< proveedores.length; i++){
            for(let j=0; j< this.state.provsnif.length; j++){
              if(this.state.provsnif[j]?.includes(proveedores[i])){
                let e = this.state.proveedorDatos[this.state.provsnif[j]]
                if(e.documentos !== 'undefined' && (e.documentos?.certificadoSS?.pendiente || e.documentos?.declaracionResponsable?.pendiente || e.documentos?.evaluacionRiesgos?.pendiente || e.documentos?.justificante?.pendiente
                  || e.documentos?.lopd?.pendiente || e.documentos?.planEmergencia?.pendiente || e.documentos?.planPrevencion?.pendiente || e.documentos?.planificacionPreventiva?.pendiente 
                  || e.documentos?.seguro?.pendiente || e.documentos?.vigilancia?.pendiente)){
                    nprof++;
                  }
                } 
              }
            }
          aaffData.nFincas = nfincas
          aaffData.proveedores = proveedores
          aaffData.nProveedores = proveedores.length
          aaffData.fincasVisitadas = fincasVisitadas
          aaffData.docsPendientes = nprof
          aaffs.push(aaffData)
        }))
      })
      
      
      Promise.all(promises).then(() => {
        
        this.setState({ aaff: aaffs, isLoading: false})
        
        
        
      })
    })
  }
  
  
  loadTecnicosConsultoresComerciales = () => {
    let tecnicos = {}
    let consultores = {}
    let comerciales = {}
    let promises = []
    promises.push(firestore.collection("tecnicos").get().then(snapshot => {
      snapshot.docs.forEach(tecnico => {
        tecnicos = { ...tecnicos, [tecnico.data().nif]: tecnico.data() }
      })
    }))
    promises.push(firestore.collection("consultores").get().then(snapshot => {
      snapshot.docs.forEach(consultor => {
        consultores = { ...consultores, [consultor.data().nif]: consultor.data() }
      })
    }))
    promises.push(firestore.collection("comerciales").get().then(snapshot => {
      snapshot.docs.forEach(comercial => {
        comerciales = { ...comerciales, [comercial.data().nif]: comercial.data() }
      })
    }))
    Promise.all(promises).then(() => {
      this.setState({ tecnicos: tecnicos, consultores: consultores, comerciales: comerciales })
    })
  }
  subirListado = (res) => {
    toast.info("Subiendo AAFFs...")
    let promises = []
    let regexp = new RegExp("(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)")
    res.forEach(json => {
      if (regexp.test(json.nif)) {
        promises.push(firestore.collection("aaff").doc(json.nif?.toUpperCase()).set({
          nif: json.nif?.toUpperCase(),
          razonSocial: json.razonSocial?.toUpperCase() || "",
          email: json.email,
          municipio: json.municipio?.toUpperCase() || "",
          nifConsultor: "",
          nifTecnico: ""
        }))
      } else {
        toast.error("NIF incorrecto: " + json.nif)
      }
    });
    Promise.all(promises).then(resp => {
      toast.info("Listado subido con éxito")
      this.loadPageContent()
    }).catch(err => {
      toast.error("Error al cargar la lista")
      console.log(err)
    })
  }

  descargar = (ruta) => {
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      storage.ref(ruta).getDownloadURL().then(url => {
        window.open(url, "_blank")
      }).catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al descargar el archivo")
      })
    }
  }

  ordenarVisitas = () => {
    let aaff = this.state.aaff
    aaff.sort((a, b) => {
      if (a.nFincas === 0) {
        return 1; //Sin fincas al final
      }
      if (b.nFincas === 0) {
        return -1; //Sin fincas al final
      }

      if (a.fincasVisitadas / a.nFincas > b.fincasVisitadas / b.nFincas) {
        return 1;
      }
      if (a.fincasVisitadas / a.nFincas < b.fincasVisitadas / b.nFincas) {
        return -1;
      }
      return 0;
    })
    //console.log(aaff)
    this.setState({ aaff: aaff })
  }


  componentWillUnmount() {
    //this._isMounted = false;
  }
  render() {
    if (this.state.aaff === []) return <></> //Loading data...
    return (
      <>
        <div className="pageHeader"  >Administradores de Fincas</div>
        <div className="pageContent tableContainer">
          <div className="tableWrapper">
            <table>
              <thead>
                <tr>
                  <th>Administrador</th>
                  <th>NIF</th>
                  <th>Municipio</th>
                  <th>Consultor</th>
                  <th>Comercial</th>
                  <th>Técnico</th>
                  <th style={{ textAlign: "center", cursor: "pointer" }} onClick={this.ordenarVisitas}>Comunidades Visitadas</th>
                  <th style={{ textAlign: "center" }}>Nº Proveedores</th>
                  <th style={{ textAlign: "center" }}></th>
                  <th style={{ textAlign: "center" }}>Proveedores</th>
                </tr>
              </thead>
              <tbody>
                {this.state.isLoading ? <tr><td colSpan='10' style={{textAlign:'center'}}><CircularProgress style={{color:'#848474'}} /> </td></tr> :
                this.state.aaff.length === 0 ? <tr><td colSpan='10' style={{textAlign:'center'}}>No tiene administradores</td></tr> :
                this.state.aaff.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td><Link to={"/aaff/" + e.nif + "/comunidades"}>{e.razonSocial}</Link></td>
                      <td><Link to={"/aaff/" + e.nif + "/comunidades"}>{e.nif}</Link></td>
                      <td><Link to={"/aaff/" + e.nif + "/comunidades"}>{e.municipio}</Link></td>
                      <td>{e.nifConsultor === "" ? "-" : this.state.consultores[e.nifConsultor]?.nombre + " " + this.state.consultores[e.nifConsultor]?.apellidos}</td>
                      <td>{e.nifComercial === "" || e.nifComercial === undefined ? "-" : this.state.comerciales[e.nifComercial]?.nombre + " " + this.state.comerciales[e.nifComercial]?.apellidos}</td>
                      <td>{e.nifTecnico === "" ? "-" : this.state.tecnicos[e.nifTecnico]?.nombre + " " + this.state.tecnicos[e.nifTecnico]?.apellidos}</td>
                      <td style={{ textAlign: "center" }}><Link to={"/aaff/" + e.nif + "/comunidades"}><LinearProgressWithLabel value={e.fincasVisitadas / (e.nFincas || 1) * 100} label={e.fincasVisitadas + '/' + e.nFincas} variant="determinate" /></Link></td>
                      <td style={{ textAlign: "center" }}><Link to={`/aaff/${e.nif}/proveedores`}>{e.nProveedores}</Link></td>
                      {/* <td>{this.state.docsPendientes[i]?.docsPendientesValidacion>0 ? <img src={Warning} alt="" width="15px" style={{ marginLeft: 10 }}/> : ""}</td> */}
                      <td>{e.docsPendientes > 0 ? <img src={Warning} alt="" width="15px" style={{ marginLeft: 10 }}/> : ""}</td>
                      <td style={{ textAlign: "center" }}><Link to={`/aaff/${e.nif}/proveedores`}><img src={Lupa} alt="Proveedores" className="tableIcon"></img></Link></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}

export default AAFF;

