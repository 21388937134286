import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { toast } from "react-toastify";
import { firestore } from "../../../providers/firebase";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    margin: theme.spacing(1),
    width: '100ch',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: ' repeat(2, 1fr)',
    gridGap: '15px',
    fontSize: '12px',
  },
}));

export default function TransitionsModal(props) {
  var d = new Date(props.proveedor?.ultimaReclamacion?.seconds*1000)
  var mm = d.getMonth() + 1 <10 ? '0'+(d.getMonth() + 1) : d.getMonth() + 1;
  var dd = d.getDate() <10 ? '0'+d.getDate():d.getDate();
  var yy = d.getFullYear();
  let nuevoFormato = yy+"-"+mm+"-"+dd
  const classes = useStyles();
  const [editarDatos, setEditarDatos] = useState({
    razonSocial: props.proveedor?.razonSocial,
    direccion: props.proveedor?.direccion,
    nif: props.proveedor?.nif,
    municipio: props.proveedor?.municipio,
    personaContacto: props.proveedor?.contacto.nombre,
    codigoPostal: props.proveedor?.codigoPostal,
    emailLogin: props.proveedor?.emailLogin,
    email: props.proveedor?.contacto.email,
    provincia: props.proveedor?.provincia,
    telefono: props.proveedor?.contacto.telefono,
    ultimaReclamacion: nuevoFormato,
    comentarios: props.proveedor?.comentarios
  });
  

  function handleChange(evt) {
    const value = evt.target.value;
    setEditarDatos({
      ...editarDatos,
      [evt.target.name]: value
    });
  }

  const editar = (proveedor) => {
    let d = '';
    let c = '';
    if(proveedor.ultimaReclamacion !== "NaN-NaN-NaN"){ d = new Date(proveedor.ultimaReclamacion)}else{ d = ''}
    if(proveedor.comentarios === undefined){c= "-"}else{ c= proveedor.comentarios}
    //console.log(d)
    firestore.collection("proveedores").doc(props.proveedor?.nif).update({
      razonSocial: proveedor.razonSocial,
      direccion: proveedor.direccion,
      nif: proveedor.nif,
      municipio: proveedor.municipio,
      codigoPostal: proveedor.codigoPostal,
      provincia: proveedor.provincia,
      emailLogin: proveedor.emailLogin,
      ultimaReclamacion: d,
      comentarios: c,
      contacto: {
        nombre: proveedor.personaContacto,
        email: proveedor.email,
        telefono: proveedor.telefono,
      }
    }).then(() => {
      toast.success("Datos editados con éxito")
      props.handleClose()
    }).catch(err => {
      console.log(err)
      toast.error("Error al editar los datos")
    })
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.opened}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.opened}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Editar datos</h2>
            <div id="transition-modal-description">
              <form  noValidate autoComplete="off">
                <div className={classes.form}>
                  <TextField id="razonSocial" name="razonSocial" label="Nombre proveedor" value={editarDatos.razonSocial} onChange={handleChange} />
                  <TextField id="direccion" name="direccion" label="Dirección" value={editarDatos.direccion} onChange={handleChange} />
                  <TextField id="municipio" name="municipio" label="Municipio" value={editarDatos.municipio} onChange={handleChange} />
                  <TextField id="personaContacto" name="personaContacto" label="Persona de contacto" value={editarDatos.personaContacto} onChange={handleChange} />
                  <TextField id="email" name="email" label="Email de contacto" value={editarDatos.email} onChange={handleChange} />
                  <TextField id="email" name="emailLogin" label="Email login" value={editarDatos.emailLogin} onChange={handleChange} />
                  <TextField id="codigoPostal" type="number" name="codigoPostal" label="C.P." value={editarDatos.codigoPostal} onChange={handleChange} />
                  <TextField id="provincia" name="provincia" label="Provincia" value={editarDatos.provincia} onChange={handleChange} />
                  <TextField id="telefono" type="number" name="telefono" label="Teléfono" value={editarDatos.telefono} onChange={handleChange} />
                  <TextField id="ultimaReclamacion" type="date" InputLabelProps={{ shrink: true }} name="ultimaReclamacion" label="Última Reclamación" defaultValue={editarDatos.ultimaReclamacion} onChange={handleChange} />
                  <div></div>
                </div>
                <TextField style={{display:'flex'}} id="comentarios" name="comentarios" label="Comentarios" value={editarDatos.comentarios} onChange={handleChange} multiline rows={6} columns={100}/>
                <div className={classes.form}>
                  <Button variant="contained" onClick={props.handleClose}>Cancelar</Button>
                  <Button className="btn-Secondary" variant="contained" color="secondary" onClick={() => editar(editarDatos)}>Editar</Button>
                </div>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}