import React from "react";
import { firestore } from "../../../providers/firebase";
import { withRouter } from "../../../providers/withRouter";
import { toast } from "react-toastify";
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from "react-router-dom";
import { abrirArchivo, subirDocumentacionProveedor, subirDeclaracionResponsable } from "../../../providers/documentos";

import orderArray from "../../../providers/orderArray";
import { serviciosProveedores } from "../../../providers/serviciosProveedores";
import ControlledAccordions from "./accAAFFCom";
import ModalEditar from './editarDatosProv'
import { IoDocumentTextOutline } from "react-icons/io5";
import { FiUploadCloud } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import { TiMinus } from "react-icons/ti";
import { FaRegEdit } from "react-icons/fa";

const CustomSwitch = withStyles({
  switchBase: {
    color: "#FF5252",
    '&$checked': {
      color: "#7bc977",
    },
    '&$checked + $track': {
      backgroundColor: "#7bc977",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#FF5252"
  },
})(Switch);

const CustomPendienteSwitch = withStyles({
  switchBase: {
    color: "#FFD700"
  },
  checked: {},
  track: {
    backgroundColor: "#FFD700"
  },
})(Switch);

const CustomCaducadoSwitch = withStyles({
  switchBase: {
    color: "#FC5F00"
  },
  checked: {},
  track: {
    backgroundColor: "#FC5F00"
  },
})(Switch);

class Proveedor extends React.Component {
  constructor() {
    super()
    this.state = {
      aaff: {},
      proveedor: undefined,
      modalEditarOpened: false,
      dialog: {
        opened: false,
        title: "",
        docName: "",
        finca: undefined,
        fechaCaducidad: undefined,
        nifAAFF: undefined
      },
      verDocumentacion: {
        opened: false,
        nombreDoc: undefined
      },
      subirDocumentacion: {
        opened: false,
        nombreDoc: undefined,
        fecha: undefined
      }
    }
  }
  componentDidMount() {
    /* firestore.collection("aaff").doc(this.props.params.nifAAFF).get().then(doc => this.setState({ aaff: doc.data() })).catch(err => window.location.replace("/aaff"))*/
    this.loadPageContent() 
  }
  loadPageContent = () => {
    firestore.collection("fincas").where("nifProveedores", "array-contains", this.props.params.nifProveedor).orderBy("razonSocial").get().then(snapshot => {
      let aaffs = []
      let nifAAFF = []
      snapshot.docs.forEach(doc => {
        let finca = doc.data()
        if(doc.data().activo !== false && doc.data().activo !== "false"){
          nifAAFF.push(finca.nifAAFF)
        }
      })
      nifAAFF = [...new Set(nifAAFF)]
      let promises = []
      nifAAFF.forEach(aaff => promises.push(firestore.collection("aaff").doc(aaff).get().then(docAAFF => aaffs.push(docAAFF.data()))))
      Promise.all(promises).then(() => {
        this.setState({ aaffs: orderArray(aaffs) })
        console.log(aaffs)
      })
    })
    firestore.collection("proveedores").doc(this.props.params.nifProveedor).get().then(snapshot => {
      let prov = snapshot.data();
      //let regexp = new RegExp("(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)")
      let declResp = {}
      for (let nifAAFF in prov.documentos?.declaracionResponsable) {
        //if (regexp.test(nifAAFF)) {
          declResp[nifAAFF] = { ...prov.documentos?.declaracionResponsable?.[nifAAFF] }
        //}
      }
      this.setState({ prov: prov, declResp: declResp })

    })
    firestore.collection("proveedores").doc(this.props.params.nifProveedor).get().then(snapshot => {
      let proveedor = snapshot.data();
      let fincas = [];
      
      firestore.collection("fincas").where("nifProveedores", "array-contains", this.props.params.nifProveedor).get().then(res => {
        res.docs.forEach(doc => {
          if(doc.data().activo !== false && doc.data().activo !== "false"){
            let finca = doc.data()
            finca.servicios = ""
            finca.proveedores.forEach(provserv => {
              if (provserv.nif === this.props.params.nifProveedor) {
                if (finca.servicios === "") {
                  finca.servicios += serviciosProveedores[provserv.servicio]
                } else {
                  finca.servicios += ", " + serviciosProveedores[provserv.servicio]
                }
              }
            });
  
            finca.documentos.evaluacionRiesgos.ultLectura = "Nunca"
            const ultLecturaevaluacionRiesgos = finca.documentos.evaluacionRiesgos?.leido?.[this.props.params.nifProveedor]
            if (ultLecturaevaluacionRiesgos !== undefined) {
              let fechaLectura = new Date(ultLecturaevaluacionRiesgos.seconds * 1000)
              finca.documentos.evaluacionRiesgos.ultLectura = ("0" + fechaLectura.getDate()).slice(-2) + "-" + ("0" + (fechaLectura.getMonth() + 1)).slice(-2) + "-" + fechaLectura.getFullYear() + "\n" + ("0" + fechaLectura.getHours()).slice(-2) + ":" + ("0" + fechaLectura.getMinutes()).slice(-2) + ":" + ("0" + fechaLectura.getSeconds()).slice(-2)
            }
            finca.documentos.planEmergencia.ultLectura = "Nunca"
            const ultLecturaplanEmergencia = finca.documentos.planEmergencia?.leido?.[this.props.params.nifProveedor]
            if (ultLecturaplanEmergencia !== undefined) {
              let fechaLectura = new Date(ultLecturaplanEmergencia.seconds * 1000)
              finca.documentos.planEmergencia.ultLectura = ("0" + fechaLectura.getDate()).slice(-2) + "-" + ("0" + (fechaLectura.getMonth() + 1)).slice(-2) + "-" + fechaLectura.getFullYear() + "\n" + ("0" + fechaLectura.getHours()).slice(-2) + ":" + ("0" + fechaLectura.getMinutes()).slice(-2) + ":" + ("0" + fechaLectura.getSeconds()).slice(-2)
            }
  
            fincas.push(finca)
          }
        })

        
        this.setState({ fincas: fincas, proveedor: proveedor })
        //console.log(this.state.proveedor)
      })
    }).catch(error => {
      //window.location.replace("/")
    })
  }

  validarDocumentacion = () => {
    firestore.doc(`proveedores/${this.state.proveedor.nif}`).set({
      documentos: this.state.dialog.docName === "declaracionResponsable" ? {
        declaracionResponsable: {
          [this.state.dialog.nifAAFF]: {
            validado: true,
            pendiente: false
          }
        }
      } : {
        [this.state.dialog.docName]: {
          validado: true,
          pendiente: false,
          fechaCaducidad: this.state.dialog.fechaCaducidad ? new Date(this.state.dialog.fechaCaducidad) : new Date(0)
        }
      }
    }, { merge: true })
    this.setState({ dialog: { opened: false, title: "", docName: "", proveedor: undefined, fechaCaducidad: undefined } })
    this.loadPageContent()
  }
  noValidarDocumentacion = () => {
    firestore.doc(`proveedores/${this.state.proveedor.nif}`).set({
      documentos: this.state.dialog.docName === "declaracionResponsable" ? {
        declaracionResponsable: {
          [this.state.dialog.nifAAFF]: {
            ruta: "",
            validado: false,
            pendiente: false
          }
        }
      } : {
        [this.state.dialog.docName]: {
          ruta: "",
          validado: false,
          pendiente: false,
          fechaCaducidad: ""
        }
      }
    }, { merge: true })
    this.setState({ dialog: { opened: false, title: "", docName: "", proveedor: undefined } })
    this.loadPageContent()
  }
  daysDiff = (dateFrom, dateTo) => {
    var diasdif= dateTo.getTime()-dateFrom.getTime();
    var contdias = Math.round(diasdif/(1000*60*60*24)); //mostrar la diferencia de dias
    return contdias 
  }

  render() {
    if (!this.state.proveedor || this.state.fincas === []) return null //Loading data...
    return <>
    <ModalEditar proveedor={this.state.proveedor} opened={this.state.modalEditarOpened} handleClose={() => {
        this.setState({ modalEditarOpened: false })
        this.loadPageContent()
      }} />
      <div className="pageHeader"  >Administrador / Proveedores / <b>{this.state.proveedor.razonSocial}</b></div>
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            INFORMACIÓN GENERAL
            <Link to={`/proveedores`}><div className="gridHeadRight">{"<< volver al listado de Proveedores"}</div></Link>
          </div>
          <div className="gridSubhead editarSubhead">
            <div>DATOS EMPRESA</div>
            <div className="editarBtn" onClick={() => this.setState({ modalEditarOpened: true })}><span style={{ marginRight: 10 }} >Editar</span> <FaRegEdit /></div>
          </div>
          <div className="datosGrid">
            <p><b>Nombre proveedor:</b> {this.state.proveedor.razonSocial}</p>
            <p><b>Dirección:</b> {this.state.proveedor.direccion}</p>
            <p><b>NIF:</b> {this.state.proveedor.nif}</p>
            <p><b>Municipio:</b> {this.state.proveedor.municipio}</p>
            <p><b>Persona de contacto:</b> {this.state.proveedor.contacto.nombre}</p>
            <p><b>CP:</b> {this.state.proveedor.codigoPostal}</p>
            <p><b>Email de contacto:</b> {this.state.proveedor.contacto.email}</p>
            <p><b>Provincia:</b> {this.state.proveedor.provincia}</p>
            <p><b>Email de login:</b> {this.state.proveedor.emailLogin}</p>
            <p><b>Telefono:</b> {this.state.proveedor.contacto.telefono}</p>
            <p><b>Fecha de alta:</b> {new Date(this.state.proveedor.fechaAlta.seconds * 1000).toLocaleDateString()}</p>
            <p><b>Ultima reclamación:</b> {this.state.proveedor.ultimaReclamacion?.seconds === undefined ? new Date(this.state.proveedor.fechaAlta.seconds * 1000).toLocaleDateString() : new Date(this.state.proveedor.ultimaReclamacion.seconds * 1000).toLocaleDateString()}</p>
            {/* <p><b>Servicio:</b> {this.state.fincas.proveedores.servicio <= 18 ? serviciosProveedores[this.state.fincas.proveedores.servicio] : "Desconocido"}</p> */}
          </div>
          <div className="datosGridComentarios"><p><b>Comentarios:</b></p><p style={{ whiteSpace: "pre-line" }}> {this.state.proveedor.comentarios === "" || this.state.proveedor.comentarios === undefined ? "-" : this.state.proveedor.comentarios} </p></div>
          <div className="gridSubhead">DOCUMENTOS EMPRESA</div>
          <div className="tableContainer">
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Certificado LOPD</th>
                  <th style={{ textAlign: "center" }}>Seguro Resp. Civil</th>
                  <th style={{ textAlign: "center" }}>Justificante pago SPA</th>
                  <th style={{ textAlign: "center" }}>Planificación preventiva</th>
                  <th style={{ textAlign: "center" }}>Identificación riesgos</th>
                  <th style={{ textAlign: "center" }}>Certificado SS</th>
                  <th style={{ textAlign: "center" }}>Vigilancia salud</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center" }}>{this.state.proveedor.documentos.lopd?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                    this.setState({ dialog: { opened: true, title: "¿Desea verificar la LOPD de " + this.state.proveedor.razonSocial + "?", docName: "lopd" } })
                    abrirArchivo(this.state.proveedor.documentos.lopd?.ruta)
                  }} /> :
                    <CustomSwitch checked={this.state.proveedor.documentos.lopd?.validado} onClick={this.state.proveedor.documentos.lopd?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'lopd' } }) : () => subirDocumentacionProveedor("lopd", this.state.proveedor, this.loadPageContent)} />}
                    <small style={{display:'block'}}>-</small>
                    </td>
                  <td style={{ textAlign: "center" }}>{this.state.proveedor.documentos.seguro?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                    this.setState({ dialog: { opened: true, title: "¿Desea verificar el Seguro de Responsabilidad Civil de " + this.state.proveedor.razonSocial + "?", docName: "seguro", fecha:true } })
                    abrirArchivo(this.state.proveedor.documentos.seguro?.ruta)
                  }} /> :
                    <CustomSwitch checked={this.state.proveedor.documentos.seguro?.validado} onClick={this.state.proveedor.documentos.seguro?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'seguro' } }) : () => this.setState({ subirDocumentacion: { opened: true, nombreDoc: 'seguro' } })} />}
                    <small style={{display:'block'}}>{this.state.proveedor.documentos.seguro?.fechaCaducidad !== undefined ? <span style={{color: this.daysDiff(new Date(this.state.proveedor.documentos.seguro?.fechaCaducidad?.seconds* 1000), new Date()) > 30 ? "red" : "black"}}>{new Date(this.state.proveedor.documentos.seguro?.fechaCaducidad?.seconds* 1000).toLocaleDateString()}</span> : "-"}</small>
                    </td>
                  <td style={{ textAlign: "center" }}>{this.state.proveedor.documentos.justificante?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                    this.setState({ dialog: { opened: true, title: "¿Desea verificar el Justificante de Pago SPA de " + this.state.proveedor.razonSocial + "?", docName: "justificante", fecha:true } })
                    abrirArchivo(this.state.proveedor.documentos.justificante?.ruta)
                  }} /> :
                    <CustomSwitch checked={this.state.proveedor.documentos.justificante?.validado} onClick={this.state.proveedor.documentos.justificante?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'justificante' } }) : () => this.setState({ subirDocumentacion: { opened: true, nombreDoc: 'justificante' } })} />}
                    <small style={{display:'block'}}>{this.state.proveedor.documentos.justificante?.fechaCaducidad !== undefined ? <span style={{color: this.daysDiff(new Date(this.state.proveedor.documentos.justificante?.fechaCaducidad?.seconds* 1000), new Date()) > 30 ? "red" : "black"}}>{new Date(this.state.proveedor.documentos.justificante?.fechaCaducidad?.seconds* 1000).toLocaleDateString()}</span> : "-"}</small>
                    </td>
                  <td style={{ textAlign: "center" }}>{this.state.proveedor.documentos.planificacionPreventiva?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                    this.setState({ dialog: { opened: true, title: "¿Desea verificar la Planificación Preventiva de " + this.state.proveedor.razonSocial + "?", docName: "planificacionPreventiva" } })
                    abrirArchivo(this.state.proveedor.documentos.planificacionPreventiva?.ruta)
                  }} /> :
                    <CustomSwitch checked={this.state.proveedor.documentos.planificacionPreventiva?.validado} onClick={this.state.proveedor.documentos.planificacionPreventiva?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'planificacionPreventiva' } }) : () => subirDocumentacionProveedor("planificacionPreventiva", this.state.proveedor, this.loadPageContent)} />}
                    <small style={{display:'block'}}>-</small>
                    </td>
                  <td style={{ textAlign: "center" }}>{this.state.proveedor.documentos.evaluacionRiesgos?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                    this.setState({ dialog: { opened: true, title: "¿Desea verificar la Evaluación de Riesgos de " + this.state.proveedor.razonSocial + "?", docName: "evaluacionRiesgos" } })
                    abrirArchivo(this.state.proveedor.documentos.evaluacionRiesgos?.ruta)
                  }} /> :
                    <CustomSwitch checked={this.state.proveedor.documentos.evaluacionRiesgos?.validado} onClick={this.state.proveedor.documentos.evaluacionRiesgos?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'evaluacionRiesgos' } }) : () => subirDocumentacionProveedor("evaluacionRiesgos", this.state.proveedor, this.loadPageContent)} />}
                    <small style={{display:'block'}}>-</small>
                    </td>
                  <td style={{ textAlign: "center" }}>{this.state.proveedor.documentos.certificadoSS?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                    this.setState({ dialog: { opened: true, title: "¿Desea verificar el Certificado de la Seguridad Social de " + this.state.proveedor.razonSocial + "?", docName: "certificadoSS", fecha:true } })
                    abrirArchivo(this.state.proveedor.documentos.certificadoSS?.ruta)
                  }} /> :
                    <CustomSwitch checked={this.state.proveedor.documentos.certificadoSS?.validado} onClick={this.state.proveedor.documentos.certificadoSS?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'certificadoSS' } }) : () => this.setState({ subirDocumentacion: { opened: true, nombreDoc: 'certificadoSS' } })} />}
                    <small style={{display:'block'}}>{this.state.proveedor.documentos.certificadoSS?.fechaCaducidad !== undefined ? <span style={{color: this.daysDiff(new Date(this.state.proveedor.documentos.certificadoSS?.fechaCaducidad?.seconds* 1000), new Date()) > 30 ? "red" : "black"}}>{new Date(this.state.proveedor.documentos.certificadoSS?.fechaCaducidad?.seconds* 1000).toLocaleDateString()}</span> : "-"}</small>
                    </td>
                  <td style={{ textAlign: "center" }}>{this.state.proveedor.documentos.vigilancia?.pendiente ? <CustomPendienteSwitch checked={false} onClick={() => {
                    this.setState({ dialog: { opened: true, title: "¿Desea verificar la Vigilancia de Salud de " + this.state.proveedor.razonSocial + "?", docName: "vigilancia", fecha:true } })
                    abrirArchivo(this.state.proveedor.documentos.vigilancia?.ruta)
                  }} /> :
                    <CustomSwitch checked={this.state.proveedor.documentos.vigilancia?.validado} onClick={this.state.proveedor.documentos.vigilancia?.validado ? () => this.setState({ verDocumentacion: { opened: true, nombreDoc: 'vigilancia' } }) : () => this.setState({ subirDocumentacion: { opened: true, nombreDoc: 'vigilancia' } })} />}
                    <small style={{display:'block'}}>{this.state.proveedor.documentos.vigilancia?.fechaCaducidad !== undefined ? <span style={{color: this.daysDiff(new Date(this.state.proveedor.documentos.vigilancia?.fechaCaducidad?.seconds* 1000), new Date()) > 30 ? "red" : "black"}}>{new Date(this.state.proveedor.documentos.vigilancia?.fechaCaducidad?.seconds* 1000).toLocaleDateString()}</span> : "-"}</small>
                    </td>
                </tr>
              </tbody>
            </table>
            <input type="file" id="fileElem" hidden></input>
          </div>
          <div className="detPageGrid">
            <div className="gridCard">
              <div className="gridSubhead">DECLARACIONES RESPONSABLES</div>
              <table style={{ margin: 15 }}>
                <thead>
                  <tr style={{ fontSize: 10, fontWeight: 'normal', textAlign: 'center' }}>
                    <th></th>
                    <th></th>
                    <th style={{ margin: 5 }}>Ver</th>
                    <th>Adjuntar</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.aaffs?.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{this.state.declResp[e.nif]?.pendiente ? <a onClick={() => {
                      this.setState({ dialog: { opened: true, title: "¿Desea verificar la Declaración Responsable de " + e.razonSocial+ "?", docName: "declaracionResponsable", nifAAFF: e.nif } })
                      abrirArchivo(this.state.declResp[e.nif]?.ruta)
                    }}><TiMinus size={12} color={'#fcba03'} className="tableIcon" /></a> : this.state.declResp[e.nif]?.validado ? <FaCheck size={12} color="#7bc977" className="tableIcon" /> : <ImCross size={12} color={'#FF5252'} className="tableIcon" />}</td>
                        <td style={{ width: '100%' }}>{e.razonSocial}</td>
                        <td style={{ textAlign: 'center', opacity: !this.state.declResp[e.nif]?.ruta ? 0.4 : 1 }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => abrirArchivo(this.state.declResp[e.nif]?.ruta)} /></td>
                        <td style={{ textAlign: 'center' }}><label htmlFor="declaracionResponsable" onClick={() => subirDeclaracionResponsable(e.nif, this.state.proveedor, this.loadPageContent)}><FiUploadCloud size={30} className="tableIcon" /></label></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className="gridCard">
              <div className="gridSubhead">COMUNIDADES</div>
              <ControlledAccordions AAFFS = { this.state.aaffs } proveedor = {this.state.proveedor} fincas = {this.state.fincas} />
              {/* <table style={{ margin: 15 }}>
                <thead>
                  <tr style={{ fontSize: 10, fontWeight: 'normal', textAlign: 'center' }}>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.fincas.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td style={{padding: "10px 0"}}>{e.razonSocial} <br/>
                         <small>{e.servicios}</small></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table> */}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={this.state.dialog.opened}
        onClose={() => this.setState({ dialog: { opened: false, title: "", docName: "" } })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.state.dialog.title}</DialogTitle>
        <DialogContent>
          {this.state.dialog.fecha ? 
            <DialogContentText id="alert-dialog-description">
              Indica la fecha de caducidad del documento
              <div>
                <input type="date" required value={this.state.dialog.fechaCaducidad} onChange={event => this.setState({ dialog: { ...this.state.dialog, fechaCaducidad: event.target.value } })} />
              </div>

            </DialogContentText> : ''
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.noValidarDocumentacion()} color="inherit" style={{ color: "#FF5252" }}>
            No Validar
          </Button>
          <Button onClick={() => this.validarDocumentacion()} color="inherit" style={{ color: "#7bc977" }} autoFocus>
            <b>Validar</b>
          </Button>
        </DialogActions>
      </Dialog>
      {/* VER DOCUMENTACIÓN */}
      <Dialog
        open={this.state.verDocumentacion.opened}
        onClose={() => this.setState({ verDocumentacion: { opened: false, nombreDoc: undefined } })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Qué quieres hacer con el documento?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Subir un nuevo documento implica sobreescribir el anterior
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            firestore.doc(`proveedores/${this.state.proveedor.nif}`).set({
              documentos: this.state.verDocumentacion.nombreDoc === "declaracionResponsable" ? {
                declaracionResponsable: {
                  [this.props.params.nifAAFF]: {
                    ruta: "",
                    validado: false,
                    pendiente: false
                  }
                }
              } : {
                [this.state.verDocumentacion.nombreDoc]: {
                  ruta: "",
                  validado: false,
                  pendiente: false
                }
              }
            }, { merge: true }).then(() => {
              toast.success("Documento borrado con éxito")
              this.setState({ verDocumentacion: { opened: false, nombreDoc: undefined } })
              this.loadPageContent()
            })
          }} color="inherit" style={{ color: "#848474" }}>
            Borrar documento
          </Button>
          <Button onClick={() => {
            const nombreDoc = this.state.verDocumentacion.nombreDoc
            if (["seguro", "justificante", "vigilancia", "certificadoSS"].includes(nombreDoc)) {
              this.setState({ subirDocumentacion: { opened: true, nombreDoc: nombreDoc, proveedor: this.state.proveedor } })
            } else {
              if (nombreDoc === "declaracionResponsable") {
                subirDeclaracionResponsable(this.props.params.nifAAFF, this.state.proveedor, this.loadPageContent)
              } else {
                subirDocumentacionProveedor(this.state.verDocumentacion.nombreDoc, this.state.proveedor, this.loadPageContent)
              }
            }
            this.setState({ verDocumentacion: { opened: false, nombreDoc: undefined } })
          }} color="inherit" style={{ color: "#848474" }}>
            Subir nuevo
          </Button>
          <Button onClick={() => {
            abrirArchivo(this.state.verDocumentacion.nombreDoc === "declaracionResponsable" ? this.state.proveedor.documentos.declaracionResponsable[this.props.params.nifAAFF].ruta : this.state.proveedor.documentos[this.state.verDocumentacion.nombreDoc].ruta)
            this.setState({ verDocumentacion: { opened: false, nombreDoc: undefined } })
          }} color="inherit" style={{ color: "#848474" }} autoFocus>
            <b>VER</b>
          </Button>
        </DialogActions>
      </Dialog>
      {/* SUBIR DOCUMENTACIÓN */}
      <Dialog
        open={this.state.subirDocumentacion.opened}
        onClose={() => this.setState({ subirDocumentacion: { opened: false, nombreDoc: undefined, fecha: undefined } })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Subir documentación</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Indica la fecha de caducidad del documento
            <div>
              <input type="date" required value={this.state.subirDocumentacion.fecha} onChange={event => this.setState({ subirDocumentacion: { ...this.state.subirDocumentacion, fecha: event.target.value } })} />
            </div>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            this.setState({ subirDocumentacion: { opened: false, nombreDoc: undefined, fecha: undefined } })
          }} color="inherit" style={{ color: "#848474" }} autoFocus>
            Cancelar
          </Button>
          <Button onClick={() => {
            if (this.state.subirDocumentacion.fecha !== undefined) {
              subirDocumentacionProveedor(this.state.subirDocumentacion.nombreDoc, this.state.proveedor, this.loadPageContent, this.state.subirDocumentacion.fecha)
              this.setState({ subirDocumentacion: { opened: false, nombreDoc: undefined, fecha: undefined } })
            } else {
              toast.error("Introduce la fecha de caducidad")
            }
          }} color="inherit" style={{ color: "#848474" }} autoFocus>
            <b>Guardar y elegir archivo</b>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  }
}

export default withRouter(Proveedor);
