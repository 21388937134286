import React from "react";
import { Link } from "react-router-dom";
import { firestore, storage, auth } from "../../../providers/firebase";
import { LinearProgress, withStyles, Box } from "@material-ui/core";

import { toast } from "react-toastify";
import CircularProgress from '@material-ui/core/CircularProgress';
import NoVisitadoAAFF from "../../../components/noVisitadoAAFF";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: props => ({
    borderRadius: 5,
    backgroundColor: props.value < 25 ? '#FF5252' : (props.value < 75 ? "#fcba03" : "#7bc977"),
  })
}))(LinearProgress);

function LinearProgressWithLabel(props) {
  return (
    <Box position="relative" display="flex" alignItems="center">
      <Box width="100%" mr={2}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box 
        position="absolute"
        left="43%"
        top="50%"
        minWidth={35}
        style={{
          transform: 'translate(-50%, -50%)', // Centra el texto
          color: '#000',
          fontWeight: 'bold',
          fontSize: '0.9rem',
        }}
      >
        {`${Math.round(
          props.value * 100
        ) / 100}%`}
      </Box>
    </Box>
  );
}

class AAFF extends React.Component {
  constructor() {
    super()
    this.state = {
      aaff: [],
      tecnicos: {},
      consultores: {},
      isLoading: true,
    }
  }
  componentDidMount() {
    this.loadPageContent()
  }

  loadPageContent = () => {
    let nifHojasVisita = []
    let totalHojasVisita = []
    let promises2 =[]
    
    firestore.collection("aaff").where("nifTecnico", "==", auth.currentUser.uid).get().then(snapshot => {
      let promises = []
      let aaffs = []
      snapshot.docs.forEach(aaff => {
        promises2.push(firestore.collection("hojasVisita").where("aaff.nif","==",aaff.data().nif).get().then(snapshot => {
          let hojaVisita=[]
          snapshot.docs.forEach( hv => {
            totalHojasVisita.push(hv.data())
            hojaVisita.push(hv.data().comunidad.nif)
          })
          nifHojasVisita = hojaVisita
        }))
        Promise.all(promises2).then(() =>{
    
          // console.log(nifHojasVisita)
        })
        promises.push(firestore.collection("fincas").where("nifAAFF", "==", aaff.data().nif).get().then(fincas => {
          let nFincas = 0
          let nRenovadas = 0
          let nRenovadasVisitadas = 0
          let nVisitadas = 0
          let nPendientes = 0
          let aaffData = aaff.data()
          /* return firestore.collection("fincas").where("nifAAFF", "==", aaffData.nif).where("documentos.hojaVisita.validado", "==", true).get().then(fincasCompl => {
            aaffData.nFincasCompl = fincasCompl.size
          }) */
          //aaffData.nFincas = fincas.size
          fincas.docs.forEach((f, i)=>{
            if(f.data().activo === true || f.data().activo === "true" ||f.data().activo === undefined){
              //console.log(fVisitadas)
              nFincas++
              if(nifHojasVisita.includes(f.data().nif)){
                //console.log(f.data().renovada, f.data().renovadaVisitada, f.data().activo)
                totalHojasVisita.forEach( fHv => {
                  if(fHv.comunidad.nif === f.data().nif){
                    let dias = ''
                    let year = new Date(fHv.fechaVisitaRenovacion?.seconds*1000).getFullYear()
                      if(fHv.fechaVisitaRenovacion !== undefined && year !== 1970){
                        // console.log(aaffData.nif, f.data().nif, year+" fVR")
                        dias = this.daysDiff(new Date(fHv.fechaVisitaRenovacion?.seconds*1000), new Date())
                      }else{
                        // console.log(aaffData.nif, f.data().nif+" fV")
                        dias = this.daysDiff(new Date(fHv.fechaVisita?.seconds*1000), new Date())
                      }
                      //console.log(f.nif, f.renovada, f.renovadaVisitada, dias)
                      if(f.data().renovada && !f.data().renovadaVisitada && dias > 304){
                        // console.log(aaffData.nif, f.data().nif, dias+ " RENOVADA!")
                        nRenovadas++
                      }else if(f.data().renovadaVisitada){
                        // console.log(aaffData.nif, f.data().nif+ " RENOVADA VISITADA!")
                        nRenovadasVisitadas++
                      }else{
                        // console.log(aaffData.nif, f.data().nif+ " VISITADA!")
                        nVisitadas++
                      }
                  }
                })
              }else{
                // console.log(aaffData.nif, f.data().nif+ " PENDIENTE!")
                /* if(f.data().documentos?.hojaVisita?.validado){
                  nVisitadas++
                  //console.log(f.data().nif, f.data().nifAAFF)
                }else{
                  nPendientes++
                } */
                nPendientes++
              }
            }          
          })
          aaffData.nFincas = nFincas
          aaffData.renovada = nRenovadas
          aaffData.renovadaVisitada = nRenovadasVisitadas
          aaffData.visitadas = nVisitadas
          aaffData.pendientes = nPendientes
          aaffData.fincasPendientes=nPendientes+nRenovadas
          aaffData.fincasVisitadas = nVisitadas+nRenovadasVisitadas
          //console.log(nRenovadas, nRenovadasVisitadas, nVisitadas, nPendientes)
          aaffs.push(aaffData)
        }))
      })
      Promise.all(promises).then(() => {
        //console.log(aaffs)
        
        this.setState({ aaff: aaffs, isLoading: false })
        this.ordenarAAFF();
      })
    })
  }
  daysDiff = (dateFrom, dateTo) => {
    var diasdif= dateTo.getTime()-dateFrom.getTime();
    var contdias = Math.round(diasdif/(1000*60*60*24)); //mostrar la diferencia de dias
    return contdias 
  }
  descargar = (ruta) => {
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      storage.ref(ruta).getDownloadURL().then(url => {
        window.open(url, "_blank")
      }).catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al descargar el archivo")
      })
    }
  }
  reordenar = () =>{
    let aaff = this.state.aaff
    aaff.forEach(a => {
      if(a.fechaEncargo===undefined){
        a.fechaEncargo = {seconds: 0, nanoseconds: 0}
      }
    })
    /* aaff.sort((a, b) => {
      if(a.fechaEncargo > b.fechaEncargo){
        return -1
      }
      if(a.fechaEncargo < b.fechaEncargo){
        return 1
      }
      if(a.fechaEncargo === undefined && b.fechaEncargo !== undefined){
        return 1
      }
      return 0
    }) */
    aaff.sort(function(a, b) {
      let f1 = a.fechaEncargo.seconds
      let f2 = b.fechaEncargo.seconds
      if(f1 > f2){
        //console.log("f1 - "+ (f1 - f2))
        //console.log(a.razonSocial, b.razonSocial)
        return f1 - f2;
      }
      if(f1 < f2){
        //console.log("f2 - "+ (f1 - f2))
        //console.log(a.razonSocial, b.razonSocial)
        return f2 - f1
      }
      return 0
    });
    //this.setState({aaff:aaff})
    //console.log(aaff)
  }
  ordenarAAFF = () => {
    let ArrFechaEncargo = []
    let ArrNoFechaEncargo = []
    let ArrNuevo = []
    let aaff = this.state.aaff
    //console.log(aaff)
    aaff.forEach((e,i) => {
      if(e.fechaEncargo!== undefined){
        ArrFechaEncargo.push(e)
      }else{
        ArrNoFechaEncargo.push(e)
      }
    })
    // console.log(ArrFechaEncargo)
    // console.log(ArrNoFechaEncargo)
    //console.log(aaff)
    ArrFechaEncargo.sort((a,b) => {
      let f1 = new Date(a.fechaEncargo.seconds*1000); 
      let f2 = new Date(b.fechaEncargo.seconds*1000); 
      //return a>b ? 1 : a<b ? -1 : 0;

      if(f1 > f2){
        //console.log(f1+ "a mayor b "+f2)
        return 1
      }else if(f1 < f2){
        //console.log(f1 +" a menor b "+f2)
        return -1
      }else if(f1 = f2){
        //console.log(f1 + " a igual b "+ f2)
        let r1 = a.razonSocial; 
        let r2 = b.razonSocial; 
        //console.log(r1, r2)
        return r1>r2 ? 1 : r1<r2 ? -1 : 0;
      }
      return 0
    })
    // console.log(ArrFechaEncargo)
    ArrNoFechaEncargo.sort((a,b) => {
      a = a.razonSocial; 
      b = b.razonSocial; 
      return a>b ? 1 : a<b ? -1 : 0;

    })
    ArrFechaEncargo.forEach(a => {
      ArrNuevo.push(a)
    })
    ArrNoFechaEncargo.forEach(b => {
      ArrNuevo.push(b)
    })
    //console.log(ArrNuevo)
    /* aaff.sort((a, b) => {
      let fecha = a.fechaEncargo - b.fechaEncargo
      if(a.fechaEncargo !== undefined && b.fechaEncargo === undefined){
        console.log(a.razonSocial +" 1")
        return -1
      }else if(a.fechaEncargo === undefined && b.fechaEncargo !== undefined){
        console.log(a.razonSocial +" 2")
        return 1
      }else if(a.fechaEncargo > b.fechaEncargo){
        console.log(a.razonSocial +" 3")
        return 1
      }else if(a.fechaEncargo < b.fechaEncargo){
        console.log(a.razonSocial +" 4")
        return -1
      }
    }) */
    //aaff.sort((a, b) => {
      /* if(a.fechaEncargo !== undefined){
        return -1
      }else{
        return 1
      } */
      //if(a.fechaEncargo !== undefined){
        /* if(b.fechaEncargo !== undefined){
          return 1
        }else{
          return -1
        } */
        
        /* if(b.fechaEncargo !== undefined){
          console.log("a: "+a.fechaEncargo.seconds+" "+"b "+b.fechaEncargo.seconds)
          if (a.fechaEncargo.seconds > b.fechaEncargo.seconds) {
            console.log("a mayor que b")
            console.log("a: "+new Date(a.fechaEncargo.seconds*1000).getDate()+" "+"b "+new Date(b.fechaEncargo.seconds*1000).getDate())
            
            return 1;
          }
          if (a.fechaEncargo.seconds < b.fechaEncargo.seconds) {
            console.log("b mayor que a")
            console.log("b: "+new Date(b.fechaEncargo.seconds*1000).getDate()+" "+"a "+new Date(a.fechaEncargo.seconds*1000).getDate())
            return -1;
          }
        }else{
          return -1
        } */
      /*   return -1
      }else {
        return 1
      } */
      //return 0;
    //})
    this.setState({ aaff: ArrNuevo })
  }

  render() {
    return (
      <>
        <div className="card" style={{margin:30}}>
          <div className="card-header"><span>Administradores de fincas</span></div>
          <div className="tableWrapper">
            <table>
              <thead>
                <tr>
                  <th>Administrador</th>
                  <th style={{ cursor: "pointer" }} onClick={this.ordenarAAFF}>Fecha encargo</th>
                  <th>Municipio</th>
                  <th>Nº de comunidades</th>
                  <th>Completado (visitado)</th>
                  <th>No visita</th>
                </tr>
              </thead>
              <tbody>
                {this.state.isLoading ? <tr><td colSpan='14' style={{textAlign:'center'}}><CircularProgress style={{color:'#848474'}} /> </td></tr> : 
                this.state.aaff.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td><Link to={"/aaff/" + e.nif + "/comunidades"}>{e.razonSocial}</Link></td>
                      <td><Link to={"/aaff/" + e.nif + "/comunidades"}>{e.fechaEncargo === undefined ? '-' : new Date(e.fechaEncargo.seconds*1000).toLocaleDateString()
                        }</Link></td>
                      <td><Link to={"/aaff/" + e.nif + "/comunidades"}>{e.municipio}</Link></td>
                      <td>{e.fincasVisitadas} / {e.nFincas}</td>
                      <td><LinearProgressWithLabel value={e.fincasVisitadas / (e.nFincas || 1) * 100} variant="determinate" /></td>
                      <NoVisitadoAAFF aaff={e} nif={e.nif} />
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}

export default AAFF;

