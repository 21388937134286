import React from "react";
import { withRouter } from "../../../providers/withRouter";
import { firestore } from "../../../providers/firebase";
import firebase from "firebase";
import { toast } from "react-toastify";

import { Grid } from "@material-ui/core";
import Add from "../../../icons/add.svg"

class AsignarTecnico extends React.Component {
  constructor() {
    super()
    this.state = {
      nombre: "",
      aaff: undefined,
      resultados: undefined
    }
  }

  componentDidMount() {
    firestore.collection("aaff").doc(this.props.params.nif).get().then(doc => this.setState({ aaff: doc.data() })).catch(err => window.location.replace("/aaff"))
  }

  buscar = (e) => {
    e.preventDefault();
    firestore.collection("tecnicos").where("nombre", ">=", this.state.nombre.toUpperCase()).where("nombre", "<=", this.state.nombre.toUpperCase() + '\uf8ff').get().then(snapshot => {
      let resultados = []
      snapshot.docs.forEach(res => {
        if(res.data().status){
          resultados.push(res.data())
        }
      })
      this.setState({ resultados: resultados })
    }).catch(err => {
      console.log(err)
      toast.error("Error en la busqueda")
    })
  }

  render() {
    if (this.state.aaff === undefined) return null
    return <>
      <div className="pageHeader"  >Administradores de Fincas / {this.state.aaff.razonSocial} / <b>Asignar técnico</b></div>
      <div className="pageContent">
        <div className="titleContent">Asignar técnico</div>
        <form className="nuevoAAFFForm" onSubmit={this.buscar}>
          <Grid container spacing={5} style={{ marginTop: 20, marginBottom: 20 }}>
            <Grid item xs={12} sm={4}><input type="text" placeholder="Nombre" required value={this.state.nombre} onChange={e => this.setState({ nombre: e.target.value })}></input></Grid>
          </Grid>
          <input type="submit" value="Buscar"></input>
        </form>
        <Resultados resultados={this.state.resultados} aaff={this.state.aaff} />
      </div>
    </>
  }
}

class Resultados extends React.Component {

  enviarEmail = (e) => {
    console.log(e)
    const notificarTecNuevoAAFF = firebase.app().functions('europe-west1').httpsCallable('notificarTecNuevoAAFF');
      notificarTecNuevoAAFF({emailTecnico: e.email, nombreAAFF: e.aaffNombre })
      .then( result => {
        console.log(result.data);
        //toast.success("Email enviado con éxito")
      })
  }

  asignar = (resultado) => {
    console.log(resultado)
    console.log(this.props.aaff)
    firestore.collection("aaff").doc(this.props.aaff.nif).update({
      nifTecnico: resultado.nif,
      fechaTecnico: new Date(),
      notificado: true
    }).then(() => {
      toast.success("Técnico añadido con éxito")
      console.log(resultado)
      console.log(this.props.aaff.razonSocial)
      console.log(this.props.aaff.notificado)
      this.enviarEmail({email: resultado.email, aaffNombre: this.props.aaff.razonSocial})
      if(this.props.aaff.notificado !== undefined && this.props.aaff.notificado === false){
      }
      
    }).catch(err => {
      console.log(err)
      toast.error("Error al añadir el técnico")
    })
  }
  render() {
    if (this.props.resultados === undefined) return null;
    return (
      <div className="tableContainer" style={{ marginTop: 30 }}>
        <table>
          <thead>
            <tr>
              <th>Técnico</th>
              <th>NIF</th>
              <th style={{ textAlign: "center" }}>Asignar</th>
            </tr>
          </thead>
          <tbody>
            {this.props.resultados.map((e, i) => {
              return (
                <tr key={i}>
                  <td>{e.nombre + " " + e.apellidos}</td>
                  <td>{e.nif}</td>
                  <td style={{ textAlign: "center" }}><div className="asignarButton" style={{ marginLeft: 'auto', marginRight: 'auto', cursor: 'pointer' }} onClick={() => this.asignar(e)}><img src={Add} alt="Más" />Asignar</div></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default withRouter(AsignarTecnico);
