import React from "react";
import { Link } from "react-router-dom";
import { auth, firestore, storage } from "../../../providers/firebase";
import { withRouter } from "../../../providers/withRouter";
import { toast } from "react-toastify";
import Tick from "../../../icons/tick.svg"
import Cross from "../../../icons/cross.svg"
import Minus from "../../../icons/minus.svg"
import Documento from "../../../icons/document.svg"

import { IoDocumentTextOutline } from "react-icons/io5";
import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import { TiMinus } from "react-icons/ti";


class Comunidad extends React.Component {
  constructor() {
    super()
    this.state = {
      finca: undefined,
      hojaVisita: undefined
    }
  }
  componentDidMount() {
    firestore.collection("hojasVisita").doc(this.props.params.nif).get().then(hv => {
      this.setState({hojaVisita: hv.data()})
    })
    firestore.collection("fincas").doc(this.props.params.nif).get().then(snapshot => {
      let finca = snapshot.data();
      finca.documentos.evaluacionRiesgos.ultLectura = "Nunca"
      const ultLectura = finca.documentos.evaluacionRiesgos?.leido?.[auth.currentUser.uid]
      if (ultLectura !== undefined) {
        let fechaLectura = new Date(ultLectura.seconds * 1000)
        finca.documentos.evaluacionRiesgos.ultLectura = ("0" + fechaLectura.getDate()).slice(-2) + "-" + ("0" + (fechaLectura.getMonth() + 1)).slice(-2) + "-" + fechaLectura.getFullYear() + "\n" + ("0" + fechaLectura.getHours()).slice(-2) + ":" + ("0" + fechaLectura.getMinutes()).slice(-2) + ":" + ("0" + fechaLectura.getSeconds()).slice(-2)
      }
      finca.documentos.prevencionRiesgos.ultLectura = "Nunca"
      const ultLecturaprevencionRiesgos = finca.documentos.prevencionRiesgos?.leido?.[auth.currentUser.uid]
      if (ultLecturaprevencionRiesgos !== undefined) {
        let fechaLectura = new Date(ultLecturaprevencionRiesgos.seconds * 1000)
        finca.documentos.prevencionRiesgos.ultLectura = ("0" + fechaLectura.getDate()).slice(-2) + "-" + ("0" + (fechaLectura.getMonth() + 1)).slice(-2) + "-" + fechaLectura.getFullYear() + "\n" + ("0" + fechaLectura.getHours()).slice(-2) + ":" + ("0" + fechaLectura.getMinutes()).slice(-2) + ":" + ("0" + fechaLectura.getSeconds()).slice(-2)
      }
      finca.documentos.planEmergencia.ultLectura = "Nunca"
      const ultLecturaplanEmergencia = finca.documentos.planEmergencia?.leido?.[auth.currentUser.uid]
      if (ultLecturaplanEmergencia !== undefined) {
        let fechaLectura = new Date(ultLecturaplanEmergencia.seconds * 1000)
        finca.documentos.planEmergencia.ultLectura = ("0" + fechaLectura.getDate()).slice(-2) + "-" + ("0" + (fechaLectura.getMonth() + 1)).slice(-2) + "-" + fechaLectura.getFullYear() + "\n" + ("0" + fechaLectura.getHours()).slice(-2) + ":" + ("0" + fechaLectura.getMinutes()).slice(-2) + ":" + ("0" + fechaLectura.getSeconds()).slice(-2)
      }

      this.setState({ finca: finca })
      //console.log(finca);
    })
  }
  abrirPdf = (nif, ruta) => {
    console.log(nif, ruta)
    let urlText = ''
    let nombreDoc = ''
    switch(ruta){
      case `${nif}/evaluacion-riesgos.pdf` :
        nombreDoc = 'evaluacionRiesgos'
        urlText = `/comunidades/${nif}/evaluacion-riesgos`
      break;
      case `${nif}/plan-emergencia.pdf`:
        nombreDoc = 'planEmergencia'
        urlText = `/comunidades/${nif}/PlanEmergencia`
      break;
    }
    // let urlText = `/comunidades/${nif}/PlanEmergencia`
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      if(nombreDoc === "evaluacionRiesgos"){
        firestore.collection("fincas").doc(this.props.params.nif).set({
          documentos: {
            evaluacionRiesgos: {
              leido: {
                [auth.currentUser.uid]: new Date()
              }
            }
          }
        }, { merge: true }).then(() => {
          window.open(urlText, "_blank")
          // window.location.reload()
        })
        .catch(error => {
          console.log(error)
          toast.error("Ha ocurrido un error al descargar el archivo")
        })
      }
      if(nombreDoc === "planEmergencia"){
        firestore.collection("fincas").doc(this.props.params.nif).set({
          documentos: {
            planEmergencia: {
              leido: {
                [auth.currentUser.uid]: new Date()
              }
            }
          }
        }, { merge: true }).then(() => {
          window.open(urlText, "_blank")
          // window.location.reload()
        })
        .catch(error => {
          console.log(error)
          toast.error("Ha ocurrido un error al descargar el archivo")
        })
      }
    }
  }
  abrirArchivo = (ruta) => {
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      firestore.collection("fincas").doc(this.props.params.nif).set({
        documentos: {
          planEmergencia: {
            leido: {
              [auth.currentUser.uid]: new Date()
            }
          }
        }
      }, { merge: true }).then(() => {
        storage.ref(ruta).getDownloadURL().then(url => {
          window.open(url, "_blank")
          window.location.reload()
        })
      })
      .catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al descargar el archivo")
      })
    }

  }

  abrirEvaluacionRiesgos = (ruta) => {
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      firestore.collection("fincas").doc(this.props.params.nif).set({
        documentos: {
          evaluacionRiesgos: {
            leido: {
              [auth.currentUser.uid]: new Date()
            }
          }
        }
      }, { merge: true }).then(() => {
        storage.ref(ruta).getDownloadURL().then(url => {
          window.open(url, "_blank")
          window.location.reload()
        })
      })
        .catch(error => {
          console.log(error)
          toast.error("Ha ocurrido un error al descargar el archivo")
        })
    }

  }

  abrirPrevencionRiesgos = (ruta) => {
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      firestore.collection("fincas").doc(this.props.params.nif).set({
        documentos: {
          prevencionRiesgos: {
            leido: {
              [auth.currentUser.uid]: new Date()
            }
          }
        }
      }, { merge: true }).then(() => {
        storage.ref(ruta).getDownloadURL().then(url => {
          window.open(url, "_blank")
          window.location.reload()
        })
      })
        .catch(error => {
          console.log(error)
          toast.error("Ha ocurrido un error al descargar el archivo")
        })
    }

  }

  render() {
    if (!this.state.finca) return null //Loading data...
    return <>
      <div className="pageHeader"><span>Comunidad / <b>Vecinos {this.state.finca.razonSocial}</b></span></div>
      <div className="pageContainer">
        <div className="pageCard" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="gridCard" style={{ margin: 30 }}>
            <div className="card">
              <div className="card-header">DATOS COMUNIDAD</div>
              <div className="infoContent">
                <p><b>Nombre comunidad:</b> {this.state.finca.razonSocial}</p>
                <p><b>Dirección:</b> {this.state.finca.direccion}</p>
                <p><b>NIF:</b> {this.state.finca.nif}</p>
                <p><b>Municipio:</b> {this.state.finca.municipio}</p>
                <p><b>Persona de contacto:</b> {this.state.finca.contacto.nombre}</p>
                <p><b>CP:</b> {this.state.finca.codigoPostal}</p>
                <p><b>Email:</b> {this.state.finca.contacto.email}</p>
                <p><b>Provincia:</b> {this.state.finca.provincia}</p>
                <p><b>Telefono:</b> {this.state.finca.contacto.telefono}</p>
                <p><b>Fecha de alta:</b> {new Date(this.state.finca.fechaAlta.seconds * 1000).toLocaleDateString()}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="pageCard" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="gridCard" style={{ margin: 30 }}>
            <div className="card">
              <div className="card-header">DOCUMENTOS COMUNIDAD</div>
              <table style={{ padding: 15, width: '100%' }}>
                <thead>
                  <tr style={{ fontSize: 10, fontWeight: 'normal', textAlign: 'center' }}>
                    <th style={{ width: 39 }}></th>
                    <th></th>
                    <th style={{ width: 100 }}>Última lectura</th>
                    <th style={{ margin: 5, width: 39 }}>Ver</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {this.state.hojaVisita?.fechaVisita?.seconds === undefined ?
                    <tr>
                      <td>{this.state.finca.documentos.prevencionRiesgos?.pendiente ? <TiMinus size={12} color={'#fcba03'} className="tableIcon" /> : this.state.finca.documentos.prevencionRiesgos?.validado ? <FaCheck size={12} color="#7bc977" className="tableIcon" /> : <ImCross size={12} color={'#FF5252'} className="tableIcon" />}</td>
                      <td>Plan de prevencion</td>
                      <td style={{ textAlign: 'center', whiteSpace: 'pre-line', fontSize: 12 }}>{this.state.finca.documentos.prevencionRiesgos.ultLectura}</td>
                      <td style={{ textAlign: 'center' }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirPrevencionRiesgos(this.state.finca.documentos.prevencionRiesgos?.ruta)} /></td>
                    </tr>
                  : null
                  } */}
                  <tr>
                    <td>{this.state.finca.documentos.evaluacionRiesgos?.pendiente ? <TiMinus size={12} color={'#fcba03'} className="tableIcon" /> : this.state.finca.documentos.evaluacionRiesgos?.validado ? <FaCheck size={12} color="#7bc977" className="tableIcon" /> : <ImCross size={12} color={'#FF5252'} className="tableIcon" />}</td>
                    <td>Identificación de riesgos y medidas preventivas</td>
                    <td style={{ textAlign: 'center', whiteSpace: 'pre-line', fontSize: 12 }}>{this.state.finca.documentos.evaluacionRiesgos.ultLectura}</td>
                    <td style={{ textAlign: 'center' }}>
                      {this.state.hojaVisita?.comunidad?.nif.includes(this.state.finca.nif) ?
                        <IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirPdf(this.state.finca.nif, this.state.finca.documentos.evaluacionRiesgos?.ruta)} />
                      :
                        <IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirEvaluacionRiesgos(this.state.finca.documentos.evaluacionRiesgos?.ruta)} />
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>{this.state.finca.documentos.planEmergencia?.pendiente ? <TiMinus size={12} color={'#fcba03'} className="tableIcon" /> : this.state.finca.documentos.planEmergencia?.validado ? <FaCheck size={12} color="#7bc977" className="tableIcon" /> : <ImCross size={12} color={'#FF5252'} className="tableIcon" />}</td>
                    <td>Plan de emergencia</td>
                    <td style={{ textAlign: 'center', whiteSpace: 'pre-line', fontSize: 12  }}>{this.state.finca.documentos.planEmergencia.ultLectura}</td>
                    <td style={{ textAlign: 'center' }}>
                      {this.state.hojaVisita?.comunidad.nif.includes(this.state.finca.nif) ?
                        <IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirPdf(this.state.finca.nif, this.state.finca.documentos.planEmergencia?.ruta)} />
                      :
                        <IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(this.state.finca.documentos.planEmergencia?.ruta)} />
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  }
}

export default withRouter(Comunidad);
