import React, { useState, useEffect } from "react";
import { firestore, storage } from "../../../providers/firebase";
import { PDFViewer, Document, View, Page, Text, Image, StyleSheet } from "@react-pdf/renderer"
import LogoNF from "../../../images/Logo.png"
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "../../../providers/withRouter";
import { incidencias } from "../../../providers/incidencias";
import peligro from "../../../images/peligro.png"
import extintor from "../../../images/extintor.png"
import lucesEmergencia from "../../../images/lucesEmergencia.png"
import salida from "../../../images/salida.png"

const styles = StyleSheet.create({
    body: {
        // paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: '0cm',
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderTopWidth: 0, 
        // borderRightWidth: 1, 
        // borderBottomWidth: 1 
    }, 
    tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
    }, 
    tableColHeader: { 
        width: "30%", 
        borderStyle: "solid", 
        borderWidth: 0, 
        borderLeftWidth: 0, 
        borderTopWidth: 1,
        borderRightWidth: 1,
        backgroundColor: '#848474',
        color: '#ffffff',
        fontWeight:'bold',
        textAlign:'left'
    }, 
    tableColH_1: { 
        width: "50%", 
        borderStyle: "solid", 
        borderWidth: 0, 
        borderLeftWidth: 0, 
        borderRightWidth: 1,
        borderTopWidth: 1,
        backgroundColor: '#33332d',
        color: '#ffffff',
        fontWeight:'bold',
        textAlign:'center'
    }, 
    tableColH_2: { 
        width: "50%", 
        borderStyle: "solid", 
        borderWidth: 0, 
        borderLeftWidth: 0, 
        borderTopWidth: 1,
        backgroundColor: '#33332d',
        color: '#ffffff',
        fontWeight:'bold',
        textAlign:'center'
    }, 
    tableColSH_1: { 
        width: "100%", 
        borderStyle: "solid", 
        borderWidth: 0, 
        borderLeftWidth: 0, 
        borderTopWidth: 1,
        backgroundColor: '#848474',
        color: '#ffffff',
        fontWeight:'bold',
        textAlign:'center'
    }, 
    tableCol: { 
        width: "70%", 
        borderStyle: "solid", 
        borderWidth: 0, 
        borderLeftWidth: 0, 
        borderTopWidth: 1,
    }, 
    tableCol_1: { 
        width: "50%", 
        borderStyle: "solid", 
        borderWidth: 0, 
        borderLeftWidth: 0, 
        borderTopWidth: 1,
        borderRightWidth: 1,
        padding: 3
    }, 
    tableCol_2: { 
        width: "50%", 
        borderStyle: "solid", 
        borderWidth: 0, 
        borderLeftWidth: 0, 
        borderTopWidth: 1,
        padding: 3
    }, 
    tableCell: { 
        //margin: "auto", 
        marginLeft:5,
        marginTop: 3, 
        marginBottom: 3, 
        fontSize: 7,
        fontWeight:'bold',
        textAlign:'left'
    },
    tableColHeader1: { 
        width: "100%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#eaecee',
        color: '#000000',
        fontWeight:'bold',
        textAlign:'left'
    }, 
    tableCol1: { 
        width: "100%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
    }, 
    tableCell1: { 
        marginLeft:5,
        marginTop: 3, 
        marginBottom: 3, 
        minHeight: 60,
        fontSize: 8,
        textAlign:'left'
    },
    tableCell2: { 
        marginLeft:5,
        marginTop: 3, 
        marginBottom: 3, 
        minHeight: 120,
        fontSize: 8,
        textAlign:'left'
    },
    pdfHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        position: 'fixed',
        top: 0,
        backgroundColor: '#ffffff',
        /* margin:'2cm', */
    },
    /* tablePage: {
        width: '100%',
    }, */
    /* headerSpace: {
        height: '90px',
    }, */
    div: {
        fontSize: '10px'
    },
    h1: {
        marginBottom: '30px',
        fontSize: '14px',
        fontWeight: '600',
    },
     h2: {
        fontSize: '11px',
        fontWeight: '600',
        marginTop: '10px',
        marginBottom: '10px',
    },
    h3: {
        fontSize: '9px',
        width: '100%',
        padding: '5px 10px',
        backgroundColor: '#f8f8f8',
        textTransform: 'uppercase',
        border: '1px solid #e6e6e6',
        marginTop: '25px',
        marginBottom: '15px',
        fontWeight:'bold',
    },
    h4: {
        color: '#ab2a3e',
        marginBottom: '10px',
        marginTop: '20px',
        fontSize: '9px',
        fontWeight:'bold',
    },
    h5: {
        fontSize: '10px',
        marginTop: '15px',
        marginBottom: '5px',
    },
    /* textBox: {
        border: '2px solid #e6e6e6',
        padding: '15px',
        marginTop: '15px',
    }, */
    tableRiesgos: {
        borderCollapse: 'collapse',
        width: '100%',
        tableLayout: 'fixed',
        marginBottom: '24px',
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    simpleTableTh: {
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: 1.1
    },
    /* simpleTableTd: {
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: 1.1
    }, */
    /* tableRiesgosTd: {
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: 1.1
    }, */
    /* tableRiesgosTh: {
        backgroundColor: '#375491',
        color: '#ffffff',
        fontSize: '12px',
        fontWeight: 'bold',
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: 1.1
    }, */
    simpleTable: {
        borderCollapse: 'collapse',
        width: '80%',
        margin: '0 10% 24px 10%',
    },
    simpleTableTh: {
        textTransform: 'uppercase',
    },
    subul: {
        lineHeight: '1.8',
        fontSize:'8px',
        flexDirection: "column", 
        width: 400,
        marginLeft: '8px',
    },
    ul: {
        lineHeight: '1.8',
        fontSize:'7px',
        flexDirection: "column", 
        // width: 200,
    },
    ol: {
        lineHeight: '1.8',
        flexDirection: "row", 
        marginBottom: 4,
        fontSize: '7px',
    },
    p: {
        lineHeight: '1.8',
        textAlign: 'justify',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
        fontSize: '7px',
    },
    subp: {
        lineHeight: '1.8',
        textAlign: 'justify',
        marginLeft: '8px',
        fontSize: '8px',
    },
    br: {
        display: 'block',
        margin: '10px 0 2px 0',
        lineHeight: '5px',
        content: " ",
    },
    pageTitle: {
        textAlign: 'center',
        textDecoration: 'underline',
        fontWeight: 'bold',
        fontSize: '12px',
        marginBottom: '20px'
    },
    page: {
        size: 'A4 portrait',
        margin: '2cm',
    },
    pagebreak: {
        clear: 'both',
        pageBreakAfter: 'always',
    },
    bold: { fontWeight: 'bold' }
  });

const HojaVisitaRender = (props) => {
    
    const [isLoading, setIsLoading] = useState(true)
    const [hojasVisita, setHojasVisita] = useState([])
    const [centrosTrabajo, setCentrosTrabajo] = useState({})
    const [finca, setFincas] = useState({})
    const [imagenes1, setImagenes] = useState('')
    const [inc, setIncidencias] = useState([])

    useEffect(() => {
        firestore.collection("hojasVisita").doc(props.params.nifFinca).get().then(doc => {
            const hojaVisita = doc.data()
            console.log(doc.data())
            let imgs = []
            let centroTrabajo = undefined
            doc.data().centrosTrabajo.forEach(ct => {
                if(ct.nif === props.params.nifCentroTrabajo){
                    centroTrabajo = ct
                    if(centroTrabajo.imagenes !== undefined){
                        centroTrabajo.imagenes.forEach(i => {
                            storage.ref(i).getDownloadURL().then(img => {
                                imgs.push(img)
                            })
                        })
                        setTimeout(()=>{
                            console.log(imgs)
                            setImagenes(imgs)
                        }, 2000)
                    }
                }
            })
            let inc = []
            if (centroTrabajo?.p1_1 === "s") inc.push(incidencias.p1_1)
            if (centroTrabajo?.p1_2 === "s") inc.push(incidencias.p1_2)
            if (centroTrabajo?.p1_3 === "s") inc.push(incidencias.p1_3)
            if (centroTrabajo?.p1_4 === "s") inc.push(incidencias.p1_4)
            if (centroTrabajo?.p1_5 === "s") inc.push(incidencias.p1_5)
            if (centroTrabajo?.p1_6 === "s") inc.push(incidencias.p1_6)
            if (centroTrabajo?.p1_7 === "s") inc.push(incidencias.p1_7)
            if (centroTrabajo?.p1_8 === "n") inc.push(incidencias.p1_8)
            if (centroTrabajo?.p2_0 === "s") {
                if (centroTrabajo?.p2_1 === "n") inc.push(incidencias.p2_1)
                if (centroTrabajo?.p2_2 === "n") inc.push(incidencias.p2_2)
                if (centroTrabajo?.p2_3 === "n") inc.push(incidencias.p2_3)
                if (centroTrabajo?.p2_4 === "n") inc.push(incidencias.p2_4)
                if (centroTrabajo?.p2_5 === "n") inc.push(incidencias.p2_5)
                if (centroTrabajo?.p2_5 === "n") {
                    if (centroTrabajo?.p2_6 === "n") inc.push(incidencias.p2_6)
                    if (centroTrabajo?.p2_7 === "n") inc.push(incidencias.p2_7)
                    if (centroTrabajo?.p2_8 === "n" && hojaVisita?.p2_9 === "n") inc.push(incidencias.p2_8)
                }
                if (centroTrabajo?.p2_9 === "n" && centroTrabajo?.p2_8 === "n") inc.push(incidencias.p2_9)
                if (centroTrabajo?.p2_10 === "n") inc.push(incidencias.p2_10)
            }
            if (centroTrabajo?.p3_0 === "s") {
                if (centroTrabajo?.p3_1 === "n") inc.push(incidencias.p3_1)
                if (centroTrabajo?.p3_2 === "n") inc.push(incidencias.p3_2)
                if (centroTrabajo?.p3_3 === "n") inc.push(incidencias.p3_3)
                if (centroTrabajo?.p3_4 === "n") inc.push(incidencias.p3_4)
            }
            if (centroTrabajo?.p4_0 === "s") {
                if (centroTrabajo?.p4_1 === "n") inc.push(incidencias.p4_1)
                if (centroTrabajo?.p4_2 === "n") inc.push(incidencias.p4_2)
                if (centroTrabajo?.p4_3 === "n") inc.push(incidencias.p4_3)
                if (centroTrabajo?.p4_4 === "n") inc.push(incidencias.p4_4)
                if (centroTrabajo?.p4_5 === "n") inc.push(incidencias.p4_5)
                if (centroTrabajo?.p4_6 === "n") inc.push(incidencias.p4_6)
                if (centroTrabajo?.p4_7 === "n") inc.push(incidencias.p4_7)
                if (centroTrabajo?.p4_8 === "n") inc.push(incidencias.p4_8)
                if (centroTrabajo?.p4_9 === "n") inc.push(incidencias.p4_9)
                if (centroTrabajo?.p4_10 === "n") inc.push(incidencias.p4_10)
                if (centroTrabajo?.p4_11 === "n") inc.push(incidencias.p4_11)
                if (centroTrabajo?.p4_12 === "n") inc.push(incidencias.p4_12)
                if (centroTrabajo?.p4_13 === "n") inc.push(incidencias.p4_13)
                if (centroTrabajo?.p4_14 === "n") inc.push(incidencias.p4_14)
                if (centroTrabajo?.p4_15 === "n") inc.push(incidencias.p4_15)
            }
            if (centroTrabajo?.p5_0 === "s") {
                if (centroTrabajo?.p5_2 === "n") inc.push(incidencias.p5_2)
                if (centroTrabajo?.p5_3 === "n") inc.push(incidencias.p5_3)
            }
            if (centroTrabajo?.p6_0 === "s") {
                if (centroTrabajo?.p6_2 === "n") inc.push(incidencias.p6_2)
                if (centroTrabajo?.p6_3 === "n") inc.push(incidencias.p6_3)
            }
            if (centroTrabajo?.p10_0 === "s") {
                if (centroTrabajo?.p10_1 === "n") inc.push(incidencias.p10_1)
                // if (centroTrabajo?.p10_2 === "n") inc.push(incidencias.p10_2)
                if (centroTrabajo?.p10_3 === "n") inc.push(incidencias.p10_3)
                if (centroTrabajo?.p10_4 === "n") inc.push(incidencias.p10_4)
                if (centroTrabajo?.p10_5 === "n") inc.push(incidencias.p10_5)
                if (centroTrabajo?.p10_6 === "n") inc.push(incidencias.p10_6)
            }
            if (centroTrabajo?.p10_0 === "n") inc.push(incidencias.p10_0)
            if (centroTrabajo?.p11_0 === "s") {
                if (centroTrabajo?.p11_1 === "n") inc.push(incidencias.p11_1)
                if (centroTrabajo?.p11_2 === "n") inc.push(incidencias.p11_2)
            }
            if (centroTrabajo?.p11_0 === "n") inc.push(incidencias.p11_0)
            if (centroTrabajo?.p12_0 === "s") {
                if (centroTrabajo?.p12_1 === "n") inc.push(incidencias.p12_1)
                if (centroTrabajo?.p12_2 === "n") inc.push(incidencias.p12_2)
            }
            if (centroTrabajo?.p12_0 === "n") inc.push(incidencias.p12_0)
            
            let fechaVisita = new Date(centroTrabajo?.fechaVisita?.seconds * 1000)
            let fechaVisitaRenovacion = centroTrabajo?.fechaVisitaRenovacion === undefined ? new Date (0) : new Date(centroTrabajo?.fechaVisitaRenovacion?.seconds * 1000)
            hojaVisita['diffFechas'] = hojaVisita?.fechaVisitaRenovacion?.seconds < hojaVisita?.fechaVisita?.seconds
            hojaVisita['fechaVisita'] = ("0" + fechaVisita.getDate()).slice(-2) + "-" + ("0" + (fechaVisita.getMonth() + 1)).slice(-2) + "-" + fechaVisita.getFullYear()
            hojaVisita['fechaVisitaRenovacion'] = ("0" + fechaVisitaRenovacion.getDate()).slice(-2) + "-" + ("0" + (fechaVisitaRenovacion.getMonth() + 1)).slice(-2) + "-" + fechaVisitaRenovacion.getFullYear()
            setTimeout(()=>{
                setHojasVisita(hojaVisita)
                setCentrosTrabajo(centroTrabajo)
                setIncidencias(inc)
                console.log(hojaVisita)
                console.log(centroTrabajo)
                console.log(inc)
                console.log(imgs)
                if(hojasVisita?.fechaVisitaRenovacion === undefined || hojasVisita?.diffFechas === true || fechaVisitaRenovacion.getFullYear()=== 1970){
                    hojaVisita['fechaMostrar'] = ("0" + fechaVisita.getDate()).slice(-2) + "-" + ("0" + (fechaVisita.getMonth() + 1)).slice(-2) + "-" + fechaVisita.getFullYear()
                    // console.log(fechaVisita.toLocaleDateString())
                }else{
                    hojaVisita['fechaMostrar'] = ("0" + fechaVisitaRenovacion.getDate()).slice(-2) + "-" + ("0" + (fechaVisitaRenovacion.getMonth() + 1)).slice(-2) + "-" + fechaVisitaRenovacion.getFullYear()
                    // console.log(fechaVisitaRenovacion.toLocaleDateString())
                }
                setIsLoading(false)
            },2000)
        })/* .catch(() => window.location.replace('/')) */
    },[])

    return (
        <>
            {/* {console.log(imagenes1)}
            <div style={{ display:'flex', justifyContent:'center', float:'left'  }}>
                {imagenes1.map( i => {
                    return (
                        <img style={{ width:100, flexDirection: "row", float:'left' }} src={i} alt="a"  /> 
                    )
                })}
            </div> */}
            {console.log(imagenes1)}
            {isLoading ? <CircularProgress style={{color:'#848474', position: 'absolute', top: '50%', left: '50%'}} /> :
                <PDFViewer style={{ width:"100%", height:"100vh"}}>
                    <Document size="A4" margin="2cm">
                        <Page style={styles.body}>
                            <View style={{marginTop: 20, marginBottom: 20, marginHorizontal: '0cm'}} fixed>
                                <View >
                                    <Image src={LogoNF} style={{marginVertical: 0, marginHorizontal:'auto', marginTop: 0, width:'45px'}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <View>
                                    <Text style={{ color: '#ffffff', backgroundColor: '#33332d', textAlign: 'center', marginTop: 0, marginBottom: 0, fontSize: 10, padding: 2 }}>INFORMACIÓN DEL CENTRO DE TRABAJO</Text>
                                    <View style={styles.table}> 
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}><Text fontWeight="bold">Nombre del centro de trabajo</Text></Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{centrosTrabajo?.nombre}</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}><Text fontWeight="bold">CIF del centro de trabajo</Text> </Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{hojasVisita?.comunidad.nif}</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}>Tipo de comunidad</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{centrosTrabajo?.tipo}</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}>Fecha de visita</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{hojasVisita?.fechaMostrar}</Text> 
                                                {/* <Text style={styles.tableCell}>{hojasVisita?.fechaVisitaRenovacion === undefined || new Date(hojasVisita?.fechaVisitaRenovacion).getFullYear() === 1970 || (hojasVisita?.fechaVisitaRenovacion < hojasVisita?.fechaVisita) && (Date.parse(hojasVisita?.fechaVisitaRenovacion) < Date.parse(hojasVisita?.fechaVisita)) ? hojasVisita?.fechaVisita : hojasVisita?.fechaVisitaRenovacion}</Text>  */}
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}>Dirección</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{hojasVisita?.comunidad.direccion}</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}>Localidad</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{hojasVisita?.comunidad.localidad}</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}>Provincia</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{hojasVisita?.comunidad.provincia}</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}>Breve descripción del lugar</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{centrosTrabajo?.descripcion}</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}>Administrador de fincas</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{hojasVisita?.aaff.razonSocial}</Text> 
                                            </View> 
                                        </View>
                                    </View>
                                    <Text style={styles.br} />
                                    <View style={styles.table}> 
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColH_1}> 
                                                <Text style={[styles.tableCell, {textAlign:'center'}]}><Text fontWeight="bold">RIESGO</Text></Text> 
                                            </View> 
                                            <View style={styles.tableColH_2}> 
                                                <Text style={[styles.tableCell, {textAlign:'center'}]}>MEDIDA CORRECTORA</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColSH_1}> 
                                                <Text style={[styles.tableCell, {textAlign:'center'}]}><Text fontWeight="bold">1- ¿El estado general del edificio es correcto?</Text> </Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>1.1.	Hay riesgo de desprendimiento de baldosas o elementos. Lugar de la incidencia: </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Reparar los desperfectos encontrados en el suelo.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>1.2.	Hay cristales rotos o en mal estado de puertas/ventanas. Lugar de la incidencia </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Sustituir los cristales afectados, por otros en correcto estado.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>1.3.	Hay cables eléctricos sueltos. Lugar de la incidencia: </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Canalizar todo el cableado de la instalación eléctrica mediante canaletas o pasacables. En especial las que crucen zonas de paso.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>1.4.	Las cajas de registro se encuentran abiertas. </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>
                                                    <Text>Realizar revisiones periódicas de la instalación eléctrica y de los equipos eléctricos por personal autorizado (también revisar el cableado, conectores, enchufes, etc.). </Text>
                                                    <Text></Text>
                                                    <Text>Cerrar y tapar con su respectiva caja y adecuadamente el cuadro eléctrico.</Text>
                                                </Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>1.5.	Hay riesgo de deslizamiento en las rampas de acceso. </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Se recomienda la instalación de tiras antideslizantes o realizar tratamiento del pavimento.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>1.6.	El cuadro eléctrico se encuentra abierto.</Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Proteger el cuadro eléctrico. Colocar tapa y mantenerlo cerrado.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>1.7.	El cuadro eléctrico no se encuentra señalizado. </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Se recomienda señalizar el cuadro eléctrico alertando del riesgo que puede suponer su manipulación. La señalización del cuadro eléctrico es obligatoria de acuerdo al Código Técnico de la Edificación en edificios posteriores al 2006.</Text>
                                                <Image style={{ width:'50px', marginHorizontal: 'auto' }} src={peligro} alt="Peligro" />
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColSH_1}> 
                                                <Text style={[styles.tableCell, {textAlign:'center'}]}><Text fontWeight="bold">2- ¿La comunidad dispone de ascensores?</Text> </Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>2.1. Los ascensores no están revisados. Consultar con la empresa de mantenimiento. </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Realizar un correcto mantenimiento de los ascensores. Adicionalmente es importante que las salas de máquinas de los ascensores se encuentren cerradas, que disponga de una señal de riesgos eléctrico y un cartel en las inmediaciones del cuarto de máquinas del ascensor alertando del riesgo y la prohibición de acceso a toda persona ajena al servicio.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColSH_1}> 
                                                <Text style={[styles.tableCell, {textAlign:'center'}]}><Text fontWeight="bold">3- ¿Dispone de azotea transitable?</Text> </Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>3.1. El acceso a la azotea no se realiza de forma segura</Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>
                                                    <Text>Delimitar y señalizar la zona de acceso a la azotea para evitar el acceso de cualquier persona ajena a la actividad. </Text>
                                                    <Text></Text>
                                                    <Text>Contemplar la posible necesidad de acondicionar un acceso seguro.</Text>
                                                </Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>3.2. El acceso a la azotea mediante escaleras no es seguro</Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Se recomienda instalar escaleras de servicio segura para el acceso a la azotea, o valorar con la ayuda de un profesional la instalación de un medio de acceso seguro. </Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>3.3. La azotea no dispone de protección perimetral que impida la caída de personas a diferente nivel o la existente no cumple con la altura mínima. </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>
                                                    <Text>Proteger el perímetro de la zona mediante barandillas. En caso de que la comunidad no tenga murete perimetral, éstas deben disponer de pasamanos, barra intermedia y rodapié. </Text>
                                                    <Text></Text>
                                                    <Text>Las azoteas transitables deberán disponer de protección perimetral que impida la caída de altura. En su defecto línea de vida. </Text>
                                                    <Text></Text>
                                                    <Text>Si la comunidad no dispone de ninguno se recomienda contactar con empresa especializada en trabajos en altura/construcción de edificios/arquitecto que pueda recomendar la instalación más adecuada según las características del edificio.</Text>
                                                    <Text></Text>
                                                    <Text>Mientras tanto, si se realizan trabajos en la azotea, nos deberíamos de asegurar que el trabajador/a que accede lo realiza en condiciones de seguridad y dispone de protección, como por ejemplo línea de vida portátil. Se recomienda también poner un cartel que impida el acceso sin presencia de algún miembro de la presidencia de la finca.</Text>
                                                </Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColSH_1}> 
                                                <Text style={[styles.tableCell, {textAlign:'center'}]}><Text fontWeight="bold">4- ¿Disponen de extintores?</Text> </Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>4.1. La comunidad de propietarios no dispone de número suficiente de extintores en la comunidad. </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Colocar extintores suficientes en la comunidad de propietarios. De acuerdo con el Real Decreto 2177/1996 los extintores y medidas contra incendios serán obligatorios en edificios posteriores a 1996 o que hayan sufrido una reforma estructural del edificio posterior a dicha fecha. De acuerdo al Código Técnico de la Edificación (2006) se deben instalar cada 15 m de recorrido en cada planta, como máximo, desde todo origen de evacuación.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>4.2. En la comunidad hay extintores que no están correctamente revisados. </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Revisar los extintores y medidas contra incendios. De acuerdo al Real Decreto 513/2017, se deben revisar los extintores de forma anual y retimbrarlos cada 5 años por una empresa especializada. Consultar con la empresa de mantenimiento.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>4.3 En la comunidad hay extintores que no están correctamente señalizados.  </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Señalizar los extintores. De acuerdo al Código Técnico de la Edificación (2006). La colocación de las señales es recomendable en edificios anteriores al 2006.</Text> 
                                                <Image style={{ width:'50px', marginHorizontal: 'auto' }} src={extintor} alt="Extintor" />
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>4.4. En la comunidad hay extintores que no están colgados adecuadamente. </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Colocar los extintores a la altura adecuada. De acuerdo al Real Decreto 513/2017, deben estar colgados a máximo 1,20m del suelo. Se recomienda ir adaptando a la nueva normativa los extintores instalados con anterioridad y cuya altura reglamentaria máxima era de 1,70m.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>4.5. En la comunidad hay extintores que no están en buen estado de conservación.</Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Mantener en buen estado de conservación los extintores según el Reglamento de aparatos a presión sobre extintores de incendios.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColSH_1}> 
                                                <Text style={[styles.tableCell, {textAlign:'center'}]}><Text fontWeight="bold">5- ¿Disponen de iluminación de emergencias?</Text> </Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>5.1. Los recorridos de evacuación no están iluminados.  </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Iluminar los recorridos de evacuación, de acuerdo al Código Técnico de la Edificación (2006). Se recomienda la instalación de iluminación de emergencia para minimizar las consecuencias en caso de emergencia o evacuación.</Text>
                                                <Image style={{ width:'50px', marginHorizontal: 'auto' }} src={lucesEmergencia} alt="Luces de emergencia" />
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>5.2. Las salidas de la finca no están iluminadas </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Iluminar las salidas de emergencia, de acuerdo al Código Técnico de la Edificación (2006). Se recomienda la instalación de iluminación de emergencia en las puertas de salida para minimizar las consecuencias en caso de emergencia o evacuación.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColSH_1}> 
                                                <Text style={[styles.tableCell, {textAlign:'center'}]}><Text fontWeight="bold">6- ¿Disponen de señalización de emergencias?</Text> </Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>6.1. Las salidas de las fincas no están señalizadas.</Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}> Señalizar las salidas de emergencia de acuerdo al Código Técnico de la Edificación (2006). Se recomienda la instalación de señalización de emergencia en las salidas de la comunidad para minimizar las consecuencias en caso de emergencia o evacuación.</Text>
                                                <Image style={{ width:'50px', marginHorizontal: 'auto' }} src={salida} alt="Salida" />
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>6.2. Los recorridos de evacuación no están señalizados. </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Señalizar los recorridos de evacuación de acuerdo al Código Técnico de la Edificación (2006). Se recomienda la instalación de señalización de evacuación para minimizar las consecuencias en caso de emergencia o evacuación.</Text>
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColSH_1}> 
                                                <Text style={[styles.tableCell, {textAlign:'center'}]}><Text fontWeight="bold">7- ¿Dispone de jardín?</Text> </Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>7.2. Los productos fitosanitarios no se guardan bajo llave. </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Se recomienda que estos productos estén en un lugar cerrado bajo llave.</Text>
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>7.3. No se disponen de las fichas de seguridad de los productos fitosanitarios. </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Se recomienda adquirir las fichas de seguridad de los productos químicos.</Text>
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColSH_1}> 
                                                <Text style={[styles.tableCell, {textAlign:'center'}]}><Text fontWeight="bold">8- ¿Dispone de piscina?</Text> </Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>8.2. No se dispone de protección perimetral en la piscina. </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Se recomienda colocar protección perimetral en las inmediaciones del vaso de la piscina. Consultar normativa autónoma o provincial.</Text>
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>8.3. No se dispone de aros salvavidas en la piscina. </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Se recomienda colocar aros salvavidas en la piscina. Consultar normativa autónoma o provincial.</Text>
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColSH_1}> 
                                                <Text style={[styles.tableCell, {textAlign:'center'}]}><Text fontWeight="bold">9- ¿Dispone de parking?</Text> </Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>9.1. El parking no cuenta con el número suficiente de extintores. </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Colocar extintores suficientes en el parking. De acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006 se deben instalar cada 15 m de recorrido en cada planta, como máximo, desde todo origen de evacuación.</Text>
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>9.2. Los extintores del parking no están correctamente revisados. </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Revisar los extintores del parking. De acuerdo al Real Decreto 513/2017, se deben revisar los extintores de forma anual y retimbrarlos cada 5 años por una empresa especializada.</Text>
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>9.3. Los extintores del parking no están correctamente señalizados. </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Señalar los extintores del parking. De acuerdo al Real Decreto 513/2017, debe estar señalizada la ubicación de los extintores.</Text>
                                                <Image style={{ width:'50px', marginHorizontal: 'auto' }} src={extintor} alt="Extintor" />
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>9.4. Los extintores del parking no están colgados a una altura adecuada. </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Colocar los extintores a una altura adecuada. De acuerdo al Real Decreto 513/2017, deben estar colgados a máximo 1,20m del suelo. Se recomienda ir adaptando a la nueva normativa los extintores instalados con anterioridad y cuya altura reglamentaria máxima era de 1,70m.</Text>
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>9.5. Los extintores del parking no están en buen estado de conservación</Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Mantener en buen estado de conservación los extintores según el Reglamento de aparatos a presión sobre extintores de incendios.</Text>
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>9.6. Los recorridos de evacuación del parking no están señalizados.</Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Señalizar los recorridos de evacuación del parking de acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006. Se recomienda la instalación de señalización de emergencia para minimizar las consecuencias en caso de emergencia o evacuación.</Text>
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>9.7. Las salidas de emergencia del parking no están señalizadas.</Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Señalizar las salidas de emergencia del parking de acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006. Se recomienda la instalación de señalización de emergencia para minimizar las consecuencias en caso de emergencia o evacuación.</Text>
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>9.8. Las direcciones de circulación del parking no están señalizadas.</Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Pintar las direcciones de circulación del parking de acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006. Solo afecta a parkings de más de 500 metros cuadrados.</Text>
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>9.9. Las puertas de entrada y salida del parking no están señalizadas.</Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Señalizar las puertas de entrada y salida del parking. </Text>
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>9.10. El parking no dispone de sistemas de extracción de humos </Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Dptar de sistemas de extracción de humos de acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006. Esta medida aplicará en aquellos parkings con más de 500 metros cuadrados o de al menos 2 pisos.</Text>
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>9.11. El parking no dispone de BIE's.</Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Dotar de BIE´s de acuerdo al Código Técnico de la Edificación aprobado en Real Decreto 314/2006. Consultar con empresa de mantenimiento.</Text>
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>9.12. El cuadro eléctrico se encuentra abierto</Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>El cuadro eléctrico del parking debe estar cerrado/protegido de acuerdo al RD 842/2002 sobre instalaciones de Baja Tensión.</Text>
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol_1}> 
                                                <Text style={styles.tableCell}>9.13. No está señalizado el cuadro eléctrico.</Text> 
                                            </View> 
                                            <View style={styles.tableCol_2}> 
                                                <Text style={styles.tableCell}>Dotar al cuadro eléctrico del parking con el panel de "Riesgo eléctrico" en el parking. De acuerdo al RD 842/2002 sobre instalaciones de Baja Tensión se recomienda su instalación.</Text>
                                                <Image style={{ width:'50px', marginHorizontal: 'auto' }} src={peligro} alt="Peligro" />
                                            </View> 
                                        </View>
                                    </View>
                                </View>
                                <View break>
                                    <Text style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 10, marginBottom: 5, fontSize: 10, padding: 2 }}>OBSERVACIONES</Text>
                                    <View style={styles.table}> 
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol1}> 
                                                <Text style={styles.tableCell2}><Text style={{ whiteSpace: "pre-line" }}>{centrosTrabajo?.comentarios}</Text></Text> 
                                            </View> 
                                        </View>
                                    </View>
                                </View>
                            </View>
                            {imagenes1?.length > 0 ? 
                                <View style={{ marginHorizontal: '1cm' }} break>
                                    <Text style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 10, marginBottom: 5, fontSize: 10, padding: 2 }}>IMAGENES</Text>  
                                    <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {imagenes1.map((img, i) => (
                                            <View key={i} style={{ width: '25%', padding: 5 }}>
                                                <Image style={{ width: '100%' }} src={img} alt="a" />
                                            </View>
                                        ))}
                                    </View> 
                                </View>
                            : null}
                        </Page>
                    </Document>
                </PDFViewer>
            }
        </>
        
    );
}
export default withRouter(HojaVisitaRender);