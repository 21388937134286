import React from "react";
import { Link } from "react-router-dom";
import { firestore } from "../../../providers/firebase";
import { withRouter } from "../../../providers/withRouter";

import Lupa from "../../../icons/lupa.svg"
import Warning from "../../../icons/warning.svg"

import CircularProgress from '@material-ui/core/CircularProgress';

class AAFFComercial extends React.Component {
  constructor() {
    super()
    this.state = {
      comercial: {},
      aaff: [],
      docsPendientes: [],
      proveedorDatos: [],
      provsnif: [],
      isLoading: true
    }
  }
  componentDidMount() {
    let proveedores = {}
    let promises = []
    let provnif = []
    promises.push(firestore.collection("proveedores").get().then(querysnapshot => {
      querysnapshot.docs.forEach(proveedor => {
        proveedores = { ...proveedores, [proveedor.data().nif]: proveedor.data() }
        provnif.push(proveedor.data().nif)
      })
    
    }))
    Promise.all(promises).then(() => {
      this.setState({proveedorDatos:proveedores})
      this.setState({provsnif:provnif})
    })
    firestore.collection("comerciales").doc(this.props.params.nifComercial).get().then(snapshot => {
      if (!snapshot.exists) {
        window.location.replace("/comerciales")
      }
      this.setState({ comercial: snapshot.data() })
      let aaffs = []
      let promises = []
      firestore.collection("aaff").where('nifComercial', '==', snapshot.data().nif).orderBy("razonSocial").get().then(snapshot2 => {
        snapshot2.docs.forEach(doc => {
          let aaffData = doc.data()
          promises.push(firestore.collection("fincas").where("nifAAFF", "==", aaffData.nif).get().then(fincas => {
            //aaffData.nFincas = fincas.size
          let proveedores = []
          //let fincasVisitadas = 0
          let nfincas = 0
          fincas.docs.forEach(finca => {
            if(finca.data().activo === undefined || finca.data().activo === true || finca.data().activo === "true"){
              nfincas++
            }
            proveedores = proveedores.concat(finca.data().nifProveedores)
            /* if (finca.data().documentos?.hojaVisita?.validado) {
              fincasVisitadas++
            } */
          })
          proveedores = [...new Set(proveedores)]
          let nprof = 0
          for(let i=0; i< proveedores.length; i++){
            for(let j=0; j< this.state.provsnif.length; j++){
              if(this.state.provsnif[j]?.includes(proveedores[i])){
                let e = this.state.proveedorDatos[this.state.provsnif[j]]
                if(e.documentos !== 'undefined' && (e.documentos?.certificadoSS?.pendiente || e.documentos?.declaracionResponsable?.pendiente || e.documentos?.evaluacionRiesgos?.pendiente || e.documentos?.justificante?.pendiente
                  || e.documentos?.lopd?.pendiente || e.documentos?.planEmergencia?.pendiente || e.documentos?.planPrevencion?.pendiente || e.documentos?.planificacionPreventiva?.pendiente 
                  || e.documentos?.seguro?.pendiente || e.documentos?.vigilancia?.pendiente)){
                    nprof++;
                }
              } 
            }
          }
          aaffData.nFincas = nfincas
          aaffData.proveedores = proveedores
          aaffData.nProveedores = proveedores.length
          //aaffData.fincasVisitadas = fincasVisitadas
          aaffData.docsPendientes = nprof
            aaffs.push(aaffData)
          }))
        })
        Promise.all(promises).then(() => {
          this.setState({ aaff: aaffs, isLoading: false})
        })
      })
    })
  }

  render() {
    if (this.state.aaff === []) return <></> //Loading data...
    return (
      <>
        <div className="pageHeader"  >Comerciales / <b>{this.state.comercial.nombre + " " + this.state.comercial.apellidos}</b></div>
        <div className="listPage">
          <div className="gridCard">
            <div className="gridHead">
              LISTADO ADMINISTRADORES DE FINCAS
              <Link to="/comerciales"><div className="gridHeadRight">{"<< volver al listado de Comerciales"}</div></Link>
            </div>
            <div className="tableContainer">
            <div className="tableWrapper">
                <table>
                  <thead>
                    <tr>
                      <th>Administrador</th>
                      <th>NIF</th>
                      <th style={{ textAlign: "center" }}>Nº Comunidades</th>
                      <th style={{ textAlign: "center" }}></th>
                      <th style={{ textAlign: "center" }}>Proveedores</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {this.state.isLoading ? <tr><td colSpan='5' style={{textAlign:'center'}}><CircularProgress style={{color:'#848474'}} /> </td></tr> :
                    this.state.aaff.length === 0 ? <tr><td colSpan='5' style={{textAlign:'center'}}>No tiene administradores</td></tr> :
                    this.state.aaff.map((e, i) => {
                      return (
                        <tr key={i}>
                          <td>{e.razonSocial}</td>
                          <td>{e.nif}</td>
                          <td style={{ textAlign: "center" }}>{e.nFincas}</td>
                          <td>{e.docsPendientes > 0 ? <img src={Warning} alt="" width="15px" style={{ marginLeft: 10 }}/> : ""}</td>
                          <td style={{ textAlign: "center" }}><Link to={"/comerciales/" + this.state.comercial.nif + "/aaff/" + e.nif + "/proveedores"}><img src={Lupa} alt="Administradores de Fincas" className="tableIcon"></img></Link></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>              
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(AAFFComercial);

