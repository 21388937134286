import React from "react";
import { firestore, auth, storage } from "../../../providers/firebase";
import { withRouter } from "../../../providers/withRouter";
import { subirDocumentacionProveedor, abrirArchivo, subirDeclaracionResponsable } from "../../../providers/documentos"
import DescargarNegro from "../../../icons/descargar-negro.svg"
import Editar from '../../../icons/editar.svg'
import ModalEditar from './editarDatosProv'
import ModalNotificacion from './enviarNotificacion'
import orderArray from "../../../providers/orderArray";
import { toast } from "react-toastify";
import { IoDocumentTextOutline } from "react-icons/io5";
import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import { TiMinus } from "react-icons/ti";
import { FaRegEdit } from "react-icons/fa";
import { BsDownload } from "react-icons/bs";

class Proveedor extends React.Component {
  constructor() {
    super()
    this.state = {
      proveedor: undefined,
      declResp: {},
      aaffs: undefined,
      modalEditarOpened: false,
      modalNotificacionOpened: false

    }
  }
  componentDidMount() {
    this.loadPageContent()
  }

  loadPageContent = () => {
    firestore.collection("fincas").where("nifProveedores", "array-contains", auth.currentUser.uid).orderBy("razonSocial").get().then(snapshot => {
      let aaffs = []
      let nifAAFF = []
      snapshot.docs.forEach(doc => {
        let finca = doc.data()
        nifAAFF.push(finca.nifAAFF)
      })
      nifAAFF = [...new Set(nifAAFF)]
      let promises = []
      nifAAFF.forEach(aaff => promises.push(firestore.collection("aaff").doc(aaff).get().then(docAAFF => aaffs.push(docAAFF.data()))))
      Promise.all(promises).then(() => {
        this.setState({ aaffs: orderArray(aaffs) })
      })
    })
    firestore.collection("proveedores").doc(auth.currentUser.uid).get().then(snapshot => {
      let proveedor = snapshot.data();
      let regexp = new RegExp("(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)")
      let declResp = {}
      for (let nifAAFF in proveedor.documentos?.declaracionResponsable) {
        if (regexp.test(nifAAFF)) {
          declResp[nifAAFF] = { ...proveedor.documentos?.declaracionResponsable?.[nifAAFF] }
        }
      }
      this.setState({ proveedor: proveedor, declResp: declResp })

    }).catch(error => {
      window.location.replace("/")
    })
  }

  descargar = (ruta) => {
    console.log(ruta)
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      storage.ref(ruta).getDownloadURL().then(url => {
        window.open(url, "_blank")
      }).catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al descargar el archivo")
      })
    }
  }
  daysDiff = (dateFrom, dateTo) => {
    var diasdif= dateTo.getTime()-dateFrom.getTime();
    var contdias = Math.round(diasdif/(1000*60*60*24)); //mostrar la diferencia de dias
    return contdias 
  }

  render() {
    if (!this.state.proveedor || this.state.fincas === [] || !this.state.aaffs) return null //Loading data...
    return <>

      <ModalEditar proveedor={this.state.proveedor} opened={this.state.modalEditarOpened} handleClose={() => {
        this.setState({ modalEditarOpened: false })
        this.loadPageContent()
      }} />
      <ModalNotificacion proveedor={this.state.proveedor} opened={this.state.modalNotificacionOpened} handleClose={() => {
        this.setState({ modalNotificacionOpened: false })
        this.loadPageContent()
      }} />
      <div className="pageHeader"></div>
      <div className="pageContainer">
        <div className="pageCard" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="gridCard">
            <div className="card">
              <div className="card-header editarSubhead">
                <div>INFORMACIÓN GENERAL</div>
                <div className="editarBtn" onClick={() => this.setState({ modalEditarOpened: true })}><span style={{ marginRight: 10 }} >Editar</span> <FaRegEdit /></div>
              </div>
              <div className="infoContent">
                <p><b>Nombre proveedor:</b> {this.state.proveedor.razonSocial}</p>
                <p><b>Dirección:</b> {this.state.proveedor.direccion}</p>
                <p><b>NIF:</b> {this.state.proveedor.nif}</p>
                <p><b>Municipio:</b> {this.state.proveedor.municipio}</p>
                <p><b>Persona de contacto:</b> {this.state.proveedor.contacto.nombre}</p>
                <p><b>CP:</b> {this.state.proveedor.codigoPostal}</p>
                <p><b>Email de contacto:</b> {this.state.proveedor.contacto.email}</p>
                <p><b>Provincia:</b> {this.state.proveedor.provincia}</p>
                <p><b>Telefono de contacto:</b> {this.state.proveedor.contacto.telefono}</p>
                <p><b>Fecha de alta:</b> {new Date(this.state.proveedor.fechaAlta.seconds * 1000).toLocaleDateString()}</p>
              </div>
            </div>
          </div>
          <div className="gridCard">
            <div className="card">
              <div className="card-header">DOCUMENTACIÓN CAE</div>
              <table>
                <thead>
                  <tr style={{ fontSize: 10, fontWeight: 'normal', textAlign: 'center' }}>
                    <th></th>
                    <th></th>
                    <th>Caducidad</th>
                    <th style={{ margin: 5 }}>Ver</th>
                    <th>Adjuntar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{this.state.proveedor.documentos.lopd?.pendiente ? <TiMinus size={12} color={'#fcba03'} className="tableIcon" /> : this.state.proveedor.documentos.lopd?.validado ? <FaCheck size={12} color="#7bc977" className="tableIcon" /> : <ImCross size={12} color={'#FF5252'} className="tableIcon" />}</td>
                    <td style={{ width: '100%', textAlign: 'left' }}>Certificado LOPD</td>
                    <td>-</td>
                    <td style={{ textAlign: 'center', opacity: this.state.proveedor.documentos.lopd?.ruta === '' ? 0.4 : 1 }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => abrirArchivo(this.state.proveedor.documentos.lopd?.ruta)} /></td>
                    <td style={{ textAlign: 'center' }}><label htmlFor="lopd" onClick={() => subirDocumentacionProveedor("lopd", this.state.proveedor, this.loadPageContent)}><FaRegEdit className="tableIcon" /></label></td>
                  </tr>
                  <tr>
                    <td>{this.state.proveedor.documentos.seguro?.pendiente ? <TiMinus size={12} color={'#fcba03'} className="tableIcon" /> : this.state.proveedor.documentos.seguro?.validado ? <FaCheck size={12} color="#7bc977" className="tableIcon" /> : <ImCross size={12} color={'#FF5252'} className="tableIcon" />}</td>
                    <td style={{ width: '100%', textAlign: 'left' }}>Seguro de responsabilidad civil</td>
                    <td> <small style={{display:'inline-block'}}>{this.state.proveedor.documentos.seguro?.fechaCaducidad !== undefined ?  <b><span style={{color: this.daysDiff(new Date(this.state.proveedor.documentos.seguro?.fechaCaducidad?.seconds* 1000), new Date()) > 30 ? "red" : "black"}}> {new Date(this.state.proveedor.documentos.seguro?.fechaCaducidad?.seconds* 1000).toLocaleDateString()}</span></b> : "-"}</small></td>
                    <td style={{ textAlign: 'center', opacity: this.state.proveedor.documentos.seguro?.ruta === '' ? 0.4 : 1 }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => abrirArchivo(this.state.proveedor.documentos.seguro?.ruta)} /></td>
                    <td style={{ textAlign: 'center' }}><label htmlFor="seguro" onClick={() => subirDocumentacionProveedor("seguro", this.state.proveedor, this.loadPageContent)}><FaRegEdit className="tableIcon" /></label></td>
                  </tr>
                  <tr>
                    <td>{this.state.proveedor.documentos.justificante?.pendiente ? <TiMinus size={12} color={'#fcba03'} className="tableIcon" /> : this.state.proveedor.documentos.justificante?.validado ? <FaCheck size={12} color="#7bc977" className="tableIcon" /> : <ImCross size={12} color={'#FF5252'} className="tableIcon" />}</td>
                    <td style={{ width: '100%', textAlign: 'left' }}>Justificante de pago de Servicio de Prevención Ajeno </td>
                    <td><small style={{display:'inline-block'}}>{this.state.proveedor.documentos.justificante?.fechaCaducidad !== undefined ? <b><span style={{color: this.daysDiff(new Date(this.state.proveedor.documentos.justificante?.fechaCaducidad?.seconds* 1000), new Date()) > 30 ? "red" : "black"}}> {new Date(this.state.proveedor.documentos.justificante?.fechaCaducidad?.seconds* 1000).toLocaleDateString()}</span></b> : "-"}</small></td>
                    <td style={{ textAlign: 'center', opacity: this.state.proveedor.documentos.justificante?.ruta === '' ? 0.4 : 1 }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => abrirArchivo(this.state.proveedor.documentos.justificante?.ruta)} /></td>
                    <td style={{ textAlign: 'center' }}><label htmlFor="justificante" onClick={() => subirDocumentacionProveedor("justificante", this.state.proveedor, this.loadPageContent)}><FaRegEdit className="tableIcon" /></label></td>
                  </tr>
                  <tr>
                    <td>{this.state.proveedor.documentos.planificacionPreventiva?.pendiente ? <TiMinus size={12} color={'#fcba03'} className="tableIcon" /> : this.state.proveedor.documentos.planificacionPreventiva?.validado ? <FaCheck size={12} color="#7bc977" className="tableIcon" /> : <ImCross size={12} color={'#FF5252'} className="tableIcon" />}</td>
                    <td style={{ width: '100%', textAlign: 'left' }}>Planificación preventiva</td>
                    <td>-</td>
                    <td style={{ textAlign: 'center', opacity: this.state.proveedor.documentos.planificacionPreventiva?.ruta === '' || this.state.proveedor.documentos.planificacionPreventiva === undefined ? 0.4 : 1 }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => abrirArchivo(this.state.proveedor.documentos.planificacionPreventiva?.ruta)} /></td>
                    <td style={{ textAlign: 'center' }}><label htmlFor="planificacionPreventiva" onClick={() => subirDocumentacionProveedor("planificacionPreventiva", this.state.proveedor, this.loadPageContent)}><FaRegEdit className="tableIcon" /></label></td>
                  </tr>
                  <tr>
                    <td>{this.state.proveedor.documentos.evaluacionRiesgos?.pendiente ? <TiMinus size={12} color={'#fcba03'} className="tableIcon" /> : this.state.proveedor.documentos.evaluacionRiesgos?.validado ? <FaCheck size={12} color="#7bc977" className="tableIcon" /> : <ImCross size={12} color={'#FF5252'} className="tableIcon" />}</td>
                    <td style={{ width: '100%', textAlign: 'left' }}>Evaluación de riesgos</td>
                    <td>-</td>
                    <td style={{ textAlign: 'center', opacity: this.state.proveedor.documentos.evaluacionRiesgos?.ruta === '' ? 0.4 : 1 }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => abrirArchivo(this.state.proveedor.documentos.evaluacionRiesgos?.ruta)} /></td>
                    <td style={{ textAlign: 'center' }}><label htmlFor="evaluacionRiesgos" onClick={() => subirDocumentacionProveedor("evaluacionRiesgos", this.state.proveedor, this.loadPageContent)}><FaRegEdit className="tableIcon" /></label></td>
                  </tr>
                  <tr>
                    <td>{this.state.proveedor.documentos.certificadoSS?.pendiente ? <TiMinus size={12} color={'#fcba03'} className="tableIcon" /> : this.state.proveedor.documentos.certificadoSS?.validado ? <FaCheck size={12} color="#7bc977" className="tableIcon" /> : <ImCross size={12} color={'#FF5252'} className="tableIcon" />}</td>
                    <td style={{ width: '100%', textAlign: 'left' }}>Certificado corriente de pago Seguridad Social</td>
                    <td> <small style={{display:'inline-block'}}>{this.state.proveedor.documentos.certificadoSS?.fechaCaducidad !== undefined ? <b><span style={{color: this.daysDiff(new Date(this.state.proveedor.documentos.certificadoSS?.fechaCaducidad?.seconds* 1000), new Date()) > 30 ? "red" : "black"}}>{new Date(this.state.proveedor.documentos.certificadoSS?.fechaCaducidad?.seconds* 1000).toLocaleDateString()}</span></b> : "-"}</small></td>
                    <td style={{ textAlign: 'center', opacity: this.state.proveedor.documentos.certificadoSS?.ruta === '' || this.state.proveedor.documentos.certificadoSS === undefined ? 0.4 : 1 }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => abrirArchivo(this.state.proveedor.documentos.certificadoSS?.ruta)} /></td>
                    <td style={{ textAlign: 'center' }}><label htmlFor="certificadoSS" onClick={() => subirDocumentacionProveedor("certificadoSS", this.state.proveedor, this.loadPageContent)}><FaRegEdit className="tableIcon" /></label></td>
                  </tr>
                  <tr>
                    <td>{this.state.proveedor.documentos.vigilancia?.pendiente ? <TiMinus size={12} color={'#fcba03'} className="tableIcon" /> : this.state.proveedor.documentos.vigilancia?.validado ? <FaCheck size={12} color="#7bc977" className="tableIcon" /> : <ImCross size={12} color={'#FF5252'} className="tableIcon" />}</td>
                    <td style={{ width: '100%', textAlign: 'left' }}>Vigilancia de la salud del trabajador</td>
                    <td> <small style={{display:'inline-block'}}>{this.state.proveedor.documentos.vigilancia?.fechaCaducidad !== undefined ? <b><span style={{color: this.daysDiff(new Date(this.state.proveedor.documentos.vigilancia?.fechaCaducidad?.seconds* 1000), new Date()) > 30 ? "red" : "black"}}>{new Date(this.state.proveedor.documentos.vigilancia?.fechaCaducidad?.seconds* 1000).toLocaleDateString()} </span></b> : "-"}</small></td>
                    <td style={{ textAlign: 'center', opacity: this.state.proveedor.documentos.vigilancia?.ruta === '' ? 0.4 : 1 }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => abrirArchivo(this.state.proveedor.documentos.vigilancia?.ruta)} /></td>
                    <td style={{ textAlign: 'center' }}><label htmlFor="vigilancia" onClick={() => subirDocumentacionProveedor("vigilancia", this.state.proveedor, this.loadPageContent)}><FaRegEdit className="tableIcon" /></label></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="pageCard">
          <div className="gridCard">
            <div className="card">
              <div className="card-header editarSubhead">
                <div>DECLARACIONES RESPONSABLES</div>
                <div className="editarBtn" onClick={() => this.descargar("PlantillasPDF/Modelo-Declaracion-Responsable.pdf")}><span style={{ marginRight: 10 }} >Descargar modelo</span> <BsDownload /></div>
              </div>
              <table>
                <thead>
                  <tr style={{ fontSize: 10, fontWeight: 'normal', textAlign: 'center' }}>
                    <th></th>
                    <th></th>
                    <th style={{ margin: 5 }}>Ver</th>
                    <th>Adjuntar</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.aaffs.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{this.state.declResp[e.nif]?.pendiente ? <TiMinus size={12} color={'#fcba03'} className="tableIcon" /> : this.state.declResp[e.nif]?.validado ? <FaCheck size={12} color="#7bc977" className="tableIcon" /> : <ImCross size={12} color={'#FF5252'} className="tableIcon" />}</td>
                        <td style={{ width: '100%', textAlign: 'left' }}>{e.razonSocial}</td>
                        <td style={{ textAlign: 'center', opacity: !this.state.declResp[e.nif]?.ruta ? 0.4 : 1 }}><IoDocumentTextOutline size={30} className="tableIcon" onClick={() => abrirArchivo(this.state.declResp[e.nif]?.ruta)} /></td>
                        <td style={{ textAlign: 'center' }}><label htmlFor="declaracionResponsable" onClick={() => subirDeclaracionResponsable(e.nif, this.state.proveedor, this.loadPageContent)}><FaRegEdit className="tableIcon" /></label></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <input type="file" id="fileElem" hidden></input>
          </div>
        </div>
      </div>
    </>
  }
}

export default withRouter(Proveedor);
