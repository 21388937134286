import React from "react"
import { Routes, Route, Link, NavLink } from "react-router-dom";
import Edificios from "../icons/edificios.svg";
import ProveedorIcon from "../icons/proveedor.svg";

import Inicio from "./pages//aaff/Inicio";
import Comunidades from "./pages/aaff/Comunidades";
import Proveedores from "./pages/aaff/Proveedores";
import Comunidad from "./pages/aaff/Comunidad";
import Proveedor from "./pages/aaff/Proveedor";
import AAFF from "./pages/aaff/AAFF";

export function RouteBundle() {
  return (
    <Routes>
      <Route path="/" element={<AAFF />} />
      <Route path="comunidades" element={<Comunidades isMainRoute={true} />} />
      <Route path="comunidades/:nif" element={<Comunidad />} />
      <Route path="proveedores" element={<Proveedores isMainRoute={true} />} />
      <Route path="proveedores/:nif" element={<Proveedor />} />
      <Route path="aaff" element={<AAFF />} />
    </Routes>
  )
}

export function MenuLinks() {
  return (
    <>
      <NavLink to="/aaff">
        <div className="menuBtn">
          <div className="menuItem">
            <img src={Edificios} alt="Panel Principal" />
            <span>Panel Principal</span>
          </div>
        </div>
      </NavLink>
      <NavLink to="/comunidades">
        <div className="menuBtn">
          <div className="menuItem">
            <img src={Edificios} alt="Comunidades" />
            <span>Comunidades</span>
          </div>
        </div>
      </NavLink>
      <NavLink to="/proveedores">
        <div className="menuBtn">
          <div className="menuItem">
            <img src={ProveedorIcon} alt="Proveedores" />
            <span>Proveedores</span>
          </div>
        </div>
      </NavLink>
    </>
  )
}