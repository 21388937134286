import React, {useState, Component} from "react";
import { Link } from "react-router-dom";

import { LinearProgress, withStyles, Box, CircularProgress } from "@material-ui/core";
import { firestore } from "../../../providers/firebase";
import Warning from "../../../icons/warning.svg"
import orderArray from "../../../providers/orderArray";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: props => ({
    borderRadius: 5,
    backgroundColor: props.value < 25 ? '#FF5252' : (props.value < 75 ? "#fcba03" : "#7bc977"),
  })
}))(LinearProgress);

function LinearProgressWithLabel(props) {
  return (
    <Box position="relative" display="flex" alignItems="center">
      <Box width="100%" mr={2}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box
        position="absolute"
        left="43%"
        top="50%"
        minWidth={35}
        style={{
          transform: 'translate(-50%, -50%)', // Centra el texto
          color: '#000',
          fontWeight: 'bold',
          fontSize: '0.9rem',
        }}
      >
        {`${Math.round(
          props.value * 100
        ) / 100}%`}
      </Box>
    </Box>
  );
}

const ComprobarPendientes = (props) => {
  //console.log(props.nif)
  const [pendiente, setPendiente] = useState(false);
  firestore.collection("proveedores").where('nif', '==', props.nif).get().then(snapshot => {
    let data = []
    snapshot.docs.forEach(e => data.push(e.data()))
    
    let dr = data[0]?.documentos?.declaracionResponsable
    const hayDrPendiente = Object.values(dr).some(objeto => objeto.pendiente === true);
    
    data[0]?.documentos?.certificadoSS?.pendiente || hayDrPendiente || data[0]?.documentos?.declaracionResponsable?.pendiente || data[0]?.documentos?.evaluacionRiesgos?.pendiente || data[0]?.documentos?.justificante?.pendiente
    || data[0]?.documentos?.lopd?.pendiente || data[0]?.documentos?.planEmergencia?.pendiente || data[0]?.documentos?.planPrevencion?.pendiente || data[0]?.documentos?.planificacionPreventiva?.pendiente 
    || data[0]?.documentos?.seguro?.pendiente || data[0]?.documentos?.vigilancia?.pendiente ? setPendiente(true) : setPendiente(false)
  })
  //console.log(pendiente)
  return (
    <td>{pendiente ? <Link to={`/proveedores/${props.nif}`}><img src={Warning} alt="" width="30px"/></Link> : ''} </td>
  )
}
const monthDiff = (dateFrom, dateTo) => {
  return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}

const daysDiff = (dateFrom, dateTo) => {
  var diasdif= dateTo.getTime()-dateFrom.getTime();
  var contdias = Math.round(diasdif/(1000*60*60*24)); //mostrar la diferencia de dias
  return contdias 
}

class BuscadorProvs extends Component {
  constructor() {
    super()
    this.state = {
      proveedores: [],
      isLoading: true
    }
  }
  componentDidMount() {
    this.loadPageContent()
  }
  loadPageContent = () => {
    let proveedores = []
    let promises = []
    promises.push(firestore.collection("proveedores").get().then(prov => 
      prov.docs.forEach( p => {
        // console.log(p.data())
        proveedores.push(p.data())
      }),
      console.log(proveedores)
      // console.log(orderArray(proveedores))
    ))
    Promise.all(promises).then(() => {
      console.log(proveedores)
      this.setState({ proveedores: orderArray(proveedores), isLoading: false })
    })
  }

  render(){
    return (
      <>
        <div className="card" style={{margin:30}}>
          <div className="card-header"><span>Proveedores</span></div>
          <div className="tableWrapper">
              <table>
                  <thead>
                  <tr>
                      <th>Proveedor</th>
                      <th>Última Reclamación</th>
                      <th>Nif</th>
                      <th>telefono</th>
                      <th>email</th>
                      <th>Municipio</th>
                      <th>Provincia</th>
                      <th></th>
                      <th style={{ textAlign: "center" }}>Cumplimiento</th>
                  </tr>
                  </thead>
                  <tbody>
                    {this.state.isLoading ? <tr><td colSpan='10' style={{textAlign:'center'}}><CircularProgress style={{color:'#848474'}} /> </td></tr> :
                    this.state.proveedores.length === 0 ? <tr><td colSpan='10' style={{textAlign:'center'}}>No tiene proveedores</td></tr> :
                      this.state.proveedores.map((e, i) => {
                        return (
                          <tr key={i}>
                            <td><Link to={`/proveedores/${e.nif}`}>{e.razonSocial}</Link></td> 
                            <td>
                              <Link to={`/proveedores/${e.nif}`}>{
                                e.ultimaReclamacion !== undefined && e.ultimaReclamacion !== null && e.ultimaReclamacion !=='' ?
                                  daysDiff(new Date(e.ultimaReclamacion.seconds * 1000), new Date()) > 30 ? 
                                    <span style={{color: "red"}}>{new Date(e.ultimaReclamacion.seconds * 1000).toLocaleDateString()}</span> 
                                  : 
                                    <span style={{color: "black"}}>{new Date(e.ultimaReclamacion.seconds * 1000).toLocaleDateString()}</span>
                                :
                                  daysDiff(new Date(e.fechaAlta.seconds * 1000), new Date()) > 30 ? 
                                    <span style={{color: "red"}}>{new Date(e.fechaAlta.seconds * 1000).toLocaleDateString()}</span> 
                                  : 
                                    <span style={{color: "black"}}>{new Date(e.fechaAlta.seconds * 1000).toLocaleDateString()}</span>
                              }</Link>
                            </td> 
                            {/* <td><Link to={`/proveedores/${e.nif}`}></Link></td>  */}
                            <td><Link to={`/proveedores/${e.nif}`}>{e.nif}</Link></td>
                            <td><Link to={`/proveedores/${e.nif}`}>{e.telefono}</Link></td>
                            <td><Link to={`/proveedores/${e.nif}`}>{e.contacto.email}</Link></td>
                            <td><Link to={`/proveedores/${e.nif}`} >{e.municipio}</Link></td>
                            <td><Link to={`/proveedores/${e.nif}`} >{e.provincia}</Link></td>
                            <ComprobarPendientes nif={e.nif}/>
                            <td style={{ textAlign: "center" }}><LinearProgressWithLabel value={e.estado} variant="determinate" /></td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
              </table>
          </div>
        </div>
      </>
    );
  }
  
}

/* function Hit(props) {
  return (
    <div>
      <div className="hit-name">
        <Highlight attribute="razonSocial" hit={props.hit} />
      </div>
      <div className="hit-description">
        <Highlight attribute="nif" hit={props.hit} />
      </div>
      <div className="hit-price">{props.hit.municipio}</div>
    </div>
  );
}
Hit.propTypes = {
  hit: PropTypes.object.isRequired,
}; */

export default BuscadorProvs;