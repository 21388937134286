import React from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import Tick from "../../../icons/tick.svg"
import Cross from "../../../icons/cross.svg"
import { firestore, storage } from "../../../providers/firebase";

import Documento from "../../../icons/document.svg"
import { toast } from "react-toastify";
import { abrirArchivo } from "../../../providers/documentos";
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { IoDocumentTextOutline } from "react-icons/io5";

class HojasVisita extends React.Component {
  constructor() {
    super()
    this.state = {
      fincas: [],
      fincasPendientes: [],
      fincasVisitadas: [],
      tecnico: {},
      aaff: {},
      tab: 0,
      isLoading: true,
      mensaje: '',
    }
  }
  componentDidMount() {
    firestore.collection("aaff").doc(this.props.params.nifAAFF).get().then(docAAFF => {
      if (docAAFF.data().nifTecnico === this.props.params.nifTecnico) {
        firestore.collection("tecnicos").doc(this.props.params.nifTecnico).get().then(docTecnico => {
          this.setState({ tecnico: docTecnico.data(), aaff: docAAFF.data() })
        }).catch(err => window.location.replace("/tecnicos/" + this.props.params.nifTecnico + "/aaff"))
      } else {
        window.location.replace("/tecnicos/" + this.props.params.nifTecnico + "/aaff")
      }

    }).catch(err => window.location.replace("/tecnicos/" + this.props.params.nifTecnico + "/aaff"))
    this.loadPageContent()
  }

  loadPageContent = () => {
    firestore.collection("fincas").where('nifAAFF', "==", this.props.params.nifAAFF).orderBy("razonSocial", "asc").get().then(snapshot => {
      let fincas = [];
      let fincasPendientes = []
      let fincasVisitadas = []
      snapshot.forEach(e => {
        let finca = e.data()
        firestore.collection("hojasVisita").doc(finca.nif).get().then( hv => {hv.exists ? finca.existeHojaVisita = true : finca.existeHojaVisita = false } )
        
        if(finca.documentos?.evaluacionRiesgos?.validado && finca.documentos?.planEmergencia?.validado && finca.documentos?.prevencionRiesgos?.validado){
          fincasVisitadas.push(finca)
        }else{
          fincasPendientes.push(finca)
        }
        //fincas.push(finca.data())
      })
      if(this.state.tab === 0){
        this.setState({ fincas: fincasPendientes })
        this.setState({mensaje: "No hay fincas pendientes"})
      }else{
        this.setState({ fincas: fincasVisitadas })
        this.setState({mensaje: "No hay fincas visitadas"})
      }
      this.setState({ fincasPendientes: fincasPendientes, fincasVisitadas: fincasVisitadas, isLoading: false })
    })
    setTimeout(() =>{
      this.ordenarCP();
    },800)
  }

  abrirArchivo = (ruta) => {
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      storage.ref(ruta).getDownloadURL().then(url => {
        window.open(url, "_blank")
      }).catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al descargar el archivo")
      })
    }
  }

  handleChange = (event, newValue) => {
    if(newValue === 0){
      this.setState({ fincas: this.state.fincasPendientes })
      this.setState({mensaje: "No hay fincas pendientes"})
    }else{
      this.setState({ fincas: this.state.fincasVisitadas })
      this.setState({mensaje: "No hay fincas visitadas"})
    }
    this.setState({tab: newValue});
    setTimeout(() =>{
      this.ordenarCP();
    },800)
  };

  ordenarCP = () => {
    let fincas = this.state.fincas
    fincas.sort((a, b) => {
      //console.log("a: "+a.codigoPostal+" "+"b "+b.codigoPostal)
      
      if (a.codigoPostal > b.codigoPostal) {
        return 1;
      }
      if (a.codigoPostal < b.codigoPostal) {
        return -1;
      }
      return 0;
    })
    this.setState({ fincas: fincas })
  }

  render() {
    console.log(this.state.fincas)
    if (this.state.fincas === []) return <></> //Loading data...
    return (
      <>
        <div className="pageHeader"  >Técnico / {this.state.tecnico.nombre + " " + this.state.tecnico.apellidos} / {this.state.aaff.razonSocial} / <b>Hojas de visita</b></div>
        <div className="listPage">
          <div className="gridCard">
            <div className="gridHead">
              LISTADO DE COMUNIDADES
              <Link to={`/tecnicos/${this.props.params.nifTecnico}/aaff`}><div className="gridHeadRight">{"<< volver al listado de Administradores de Fincas"}</div></Link>
            </div>
            <div className="tableContainer">
              <Tabs
                value={this.state.tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handleChange}
              >
                <Tab label="No visitadas" />
                <Tab label="Visitadas" />
              </Tabs>
              <div className="tableWrapper">
                <table>
                  <thead>
                    <tr>
                      <th>Comunidad</th>
                      <th>NIF</th>
                      <th>Dirección</th>
                      <th>C.P.</th>
                      <th>Municipio</th>
                      <th>Activa</th>
                      <th>Servicios</th>
                      <th>Empleados</th>
                      <th style={{ textAlign: "center" }}>Identificación de riesgos y medidas preventivas</th>
                      <th style={{ textAlign: "center" }}>Plan de emergencia</th>
                      <th style={{ textAlign: "center" }}>Plan de prevención</th>
                      <th style={{ textAlign: "center" }}>Hoja de visita</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.isLoading ? <tr><td colSpan='12' style={{textAlign:'center'}}><CircularProgress style={{color:'#848474'}} /> </td></tr> : 
                      this.state.fincas.length===0 ? <tr><td colSpan='12' style={{textAlign:'center'}}>{this.state.mensaje}</td></tr> :
                      this.state.fincas.map((e, i) => {
                      return (
                        <tr key={i}>
                          <td>{e.razonSocial}</td>
                          <td>{e.nif}</td>
                          <td>{e.direccion}</td>
                          <td>{e.codigoPostal}</td>
                          <td>{e.municipio}</td>
                          <td>{e.activo === undefined ? "Activa" : e.activo === "true" || e.activo === true ? "Activa" : "Inactiva"}</td>
                          <td>{e.servicios === undefined ? "CAE" : e.servicios === "true" || e.servicios === true ? "SPA" : "CAE"}</td>
                          <td>{e.empleados === undefined ? "No" : e.empleados === "true" || e.empleados === true ? "Sí" : "No"}</td>
                          <td style={{ textAlign: "center" }}>{e.documentos.evaluacionRiesgos?.validado ? 
                            e.existeHojaVisita ? 
                              <Link to={`/comunidades/${e.nif}/evaluacion-riesgos`} target={'_blank'}><img src={Tick} alt="Validado" className="tableIcon" /></Link>
                            :
                              <img src={Tick} alt="Validado" className="tableIcon" onClick={() => abrirArchivo(e.documentos.evaluacionRiesgos?.ruta)} /> 
                          : 
                            <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                          <td style={{ textAlign: "center" }}>{e.documentos.planEmergencia?.validado ? 
                            e.existeHojaVisita ? 
                              <Link to={`/comunidades/${e.nif}/PlanEmergencia`} target={'_blank'}><img src={Tick} alt="Validado" className="tableIcon" /></Link>
                            :
                              <img src={Tick} alt="Validado" className="tableIcon" onClick={() => abrirArchivo(e.documentos.planEmergencia?.ruta)} /> 
                          : 
                            <img src={Cross} alt="No validado" className="tableIcon" />}
                          </td>
                          <td style={{ textAlign: "center" }}>{e.documentos.prevencionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => abrirArchivo(e.documentos.prevencionRiesgos?.ruta)} /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
                          <td style={{ textAlign: "center" }}>{e.documentos.hojaVisita?.ruta !== "" ? 
                            e.existeHojaVisita ? 
                              <Link to={`/comunidades/${e.nif}/hoja-visita`} target={'_blank'}><img src={Tick} alt="Validado" className="tableIcon" /></Link>
                            :
                              <IoDocumentTextOutline size={30} className="tableIcon" onClick={() => this.abrirArchivo(e.documentos.hojaVisita?.ruta)} /> 
                          : 
                            <IoDocumentTextOutline size={30} className="tableIcon" style={{ opacity: 0.4, cursor: "default" }} />}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(HojasVisita);

